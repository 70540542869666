import {useMutation} from '@tanstack/react-query';
import {toast} from 'react-toastify';
import * as z from 'zod';

import {first_name, last_name, email, passwordField, cvr} from '~/consts';

import {apiClient} from '../../api/apiClient';

type LoginData = {
  username: string;
  password: string;
};

type SignupData = {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  cvr?: string | null;
  join_domain: boolean | null;
  redirectKey: string;
};

type ForgotPasswordType = {
  email: string;
  redirect: string;
};

type ChangePasswordType = {
  password: string;
  verification_key: string;
};

type SelfChangePasswordType = {
  password: string;
};

export const login = {
  mutationKey: ['login'],
  mutationFn: async (loginData: LoginData) => {
    const {data} = await apiClient.post('/auth/login/secure', loginData);
    return data;
  },
};

export const signup = {
  mutationKey: ['signup'],
  mutationFn: async (signupData: SignupData) => {
    const {data} = await apiClient.post(`/admin/signup${signupData.redirectKey}`, signupData);
    return data;
  },
};

export const forgotPassword = {
  mutationKey: ['forgotPassword'],
  mutationFn: async (forgotPassword: ForgotPasswordType) => {
    const {data} = await apiClient.post(
      `/admin/forgot-password${forgotPassword.redirect}`,
      forgotPassword
    );
    return data;
  },
};

export const changePassword = {
  mutationKey: ['changePassword'],
  mutationFn: async (changePassword: ChangePasswordType) => {
    const {data} = await apiClient.post(
      `/admin/change-password?${changePassword.verification_key}`,
      changePassword
    );
    return data;
  },
};

export const selfChangePassword = {
  mutationKey: ['selfChangePassword'],
  mutationFn: async (changePassword: SelfChangePasswordType) => {
    const {data} = await apiClient.post('/admin/self-change-password', changePassword);
    return data;
  },
};

const useLogin = () => {
  const mutation = useMutation({
    ...login,
    onError: () => {
      toast.error('Something went wrong');
    },
    onSuccess: () => {},
  });

  return mutation;
};

const useSignup = () => {
  const mutation = useMutation({
    ...signup,
    onError: () => {
      toast.error('Something went wrong');
    },
    onSuccess: () => {},
  });

  return mutation;
};

const useForgotPassword = () => {
  const mutation = useMutation({
    ...forgotPassword,
  });
  return mutation;
};

const useChangePassword = () => {
  const mutation = useMutation({
    ...changePassword,
  });
  return mutation;
};

const useSelfChangePassword = () => {
  const mutation = useMutation({
    ...selfChangePassword,
  });
  return mutation;
};

const checkEmailExists = async (email: string) => {
  const {data} = await apiClient.get<boolean>(`/admin/check_email_exists/${email}`);
  return data;
};

const checkEmailDomain = async (email: string) => {
  const response = await apiClient.get<string | false>(`/admin/check_email_domain/${email}`);
  return response.data;
};

const checkVerificationStatus = async (verification_key: string) => {
  const {data} = await apiClient.get(`/admin/check_verification_status/${verification_key}`);
  return data;
};

export const RegisterSchema = z.object({
  first_name,
  last_name,
  password: passwordField,
  // .refine((value) => {
  //   const hasCapital = /[A-Z]/.test(value);
  //   const hasSmall = /[a-z]/.test(value);
  //   const hasNumber = /\d/.test(value);
  //   return hasCapital && hasSmall && hasNumber;
  // }, 'Kodeord skal indeholde mindst en stor og en lille bogstav og et tal'),
  email,
  join_domain: z.boolean().optional().default(false),
  cvr: cvr.optional(),
  checkedTerms: z.literal(true, {
    errorMap: () => ({message: 'Du skal acceptere betingelserne for at oprette en konto'}),
  }),
});

export const ChangePasswordSchema = z.object({
  password: passwordField,
});

export type ChangePasswordFormValues = z.infer<typeof ChangePasswordSchema>;

export type RegisterFormValues = z.infer<typeof RegisterSchema>;

export {
  useLogin,
  useSignup,
  checkEmailExists,
  checkEmailDomain,
  checkVerificationStatus,
  useForgotPassword,
  useChangePassword,
  useSelfChangePassword,
};
export type {SignupData, LoginData};
