import {zodResolver} from '@hookform/resolvers/zod';
import {Save} from '@mui/icons-material';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import {useEffect, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useParams} from 'react-router-dom';

import Button from '~/components/Button';
import FormInput from '~/components/FormInput';
import LinearLoading from '~/components/LineLoader';
import {verifyCvr} from '~/api/functions';
import {
  OrgDetails,
  useEditOrganization,
  OrganizationEditType,
  AddOrganizationSchema,
} from '~/hooks/queries/organizations';
import {authStore} from '~/state/store';

type EditOrganizationDialogProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  organization: OrgDetails | undefined;
};

export enum License {
  GRATIS = 'GRATIS',
  PREMIUM = 'PREMIUM',
}

const EditOrganizationDialog = ({open, setOpen, organization}: EditOrganizationDialogProps) => {
  const params = useParams();
  const org_id_parameter = params.orgId;
  const {org_id: own_org_id} = authStore();
  const default_organization_id = org_id_parameter ? org_id_parameter : own_org_id || '';
  const formMethods = useForm<OrganizationEditType>({
    resolver: zodResolver(AddOrganizationSchema),
    defaultValues: organization,
  });
  const {reset, handleSubmit, setError} = formMethods;
  const putOrg = useEditOrganization();
  const [isEditing, setIsEditing] = useState(false);
  useEffect(() => {
    if (organization) {
      reset(organization);
    }
  }, [organization, reset]);

  const handleEdit = async (values: OrganizationEditType) => {
    setIsEditing(true);

    verifyCvr(values.cvr).then((result) => {
      if (result == null) {
        setError('cvr', {type: 'custom', message: 'Denne virksomhed findes ikke i Virk.'});
        setIsEditing(false);

        return;
      }
      if ('error' in result && result.error == 'exists' && organization?.cvr !== values.cvr) {
        setError('cvr', {type: 'custom', message: 'Denne virksomhed findes allerede i Calypso.'});
        setIsEditing(false);

        return;
      } else {
        putOrg.mutate(
          {
            ...values,
            id: default_organization_id,
            website: values.website ?? '',
          },
          {
            onSuccess: () => {
              setOpen(false);
              setIsEditing(false);
            },
            onError: () => {
              setIsEditing(false);
            },
          }
        );
      }
    });
  };

  const handleClose = () => {
    setOpen(false);
    reset();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          borderRadius: 4,
          padding: 1,
          paddingBottom: 0,
          boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
        },
      }}
    >
      <DialogTitle>Rediger organisation</DialogTitle>
      <DialogContent sx={{paddingBottom: 0}}>
        <DialogContentText mb={2}>
          For at oprette en bruger bedes du udfylde nedenstående felter.
        </DialogContentText>
        <FormProvider {...formMethods}>
          <FormInput name="name" label="Navn" required fullWidth sx={{mb: 2}} />
          <FormInput name="address" label="Adresse" required fullWidth sx={{mb: 2}} />
          <FormInput name="cvr" label="CVR" required fullWidth sx={{mb: 2}} />
          <FormInput name="zip" label="Postnummer" required fullWidth sx={{mb: 2}} />
          <FormInput name="city" label="By" required fullWidth sx={{mb: 2}} />
          <FormInput name="country" label="Land" required fullWidth sx={{mb: 2}} />
          <FormInput name="website" label="Hjemmeside" fullWidth sx={{mb: 2}} />
        </FormProvider>
      </DialogContent>
      <DialogActions sx={{paddingRight: 3}}>
        <Button bttype="tertiary" onClick={handleClose}>
          Annuller
        </Button>
        <Box sx={{display: 'flex', width: 90, justifyContent: 'center'}}>
          {!isEditing ? (
            <Button bttype="primary" onClick={handleSubmit(handleEdit)} startIcon={<Save />}>
              Gem
            </Button>
          ) : (
            <LinearLoading width="large" height="medium" color="primary" />
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default EditOrganizationDialog;
