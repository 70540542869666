import DomainAddIcon from '@mui/icons-material/DomainAdd';
import {Box, Container} from '@mui/material';
import {
  MRT_ColumnDef,
  MRT_GlobalFilterTextField,
  MRT_Row,
  MRT_ShowHideColumnsButton,
  MRT_TableOptions,
  MRT_ToggleFiltersButton,
  MaterialReactTable,
} from 'material-react-table';
import {useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import Button from '~/components/Button';
import {OrgDetails} from '~/hooks/queries/organizations';
import useBreakpoints from '~/hooks/useBreakpoints';
import {TableTypes, useTable} from '~/hooks/useTable';
import {useStatefullTableAtom} from '~/hooks/useTableState';
import AddOrganizationDialog from '~/pages/organizations/AddOrganizationDialog';
import {authStore} from '~/state/store';
export type Organization = {
  address: string;
  created_at: string;
  cvr: string;
  id: string;
  is_verified: boolean;
  name: string;
  updated_at: string;
};

type OrganizationsTableProps = {
  data: OrgDetails[];
  isLoading: boolean;
  type: TableTypes;
};

const OrganizationsTable = ({data, type}: OrganizationsTableProps) => {
  const navigate = useNavigate();
  const {superUser} = authStore();
  const [isAddOrganizationDialogOpen, setIsAddOrganizationDialogOpen] = useState(false);
  const useMaxHeight = useBreakpoints().isShortHeight;
  const {isMobile} = useBreakpoints();
  const tableHeight =
    useMaxHeight && isMobile
      ? 'calc(100vh - 125px)'
      : useMaxHeight && !isMobile
        ? 'calc(100vh - 150px)'
        : !useMaxHeight && isMobile
          ? 'calc(100vh - 125px)'
          : 'calc(100vh - 150px)';

  const columns = useMemo<MRT_ColumnDef<OrgDetails>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'Organisation ID',
        // size: 50,
      },
      {
        accessorKey: 'name',
        header: 'Organisationsnavn',
        // size: isMobile ? 225 : 50,
      },
      {
        accessorKey: 'address',
        header: 'Adresse',
        // maxSize: isMobile ? 200 : 110,
      },
      {
        accessorKey: 'cvr',
        header: 'CVR',
        // maxSize: 110,
      },
    ],
    []
  );

  const handleRowClick = (row: MRT_Row<OrgDetails>) => {
    const org_id = row.original.id;
    navigate(`/organizations/${org_id}`);
  };

  const options: Partial<MRT_TableOptions<OrgDetails>> = {
    renderTopToolbar: () => {
      if (!superUser) return null;

      if (isMobile)
        return (
          <Box
            sx={{
              display: ' flex',
              flexWrap: 'wrap-reverse',
              justifyContent: 'center',
              minHeight: '100px',
            }}
          >
            <Box sx={{width: '100%', display: 'flex', justifyContent: 'space-between', mx: 0.5}}>
              <MRT_GlobalFilterTextField table={table} sx={{width: '200px'}} />
              <Box
                sx={{
                  width: '90px',
                  display: 'flex',
                  alignItems: 'start',
                  justifyContent: 'space-evenly',
                }}
              >
                <MRT_ToggleFiltersButton table={table} />
                <MRT_ShowHideColumnsButton table={table} />
              </Box>
            </Box>
            <Box sx={{width: '100%', display: 'flex', justifyContent: 'space-between', mx: 0.5}}>
              <Box sx={{display: 'flex'}}>
                <Box>
                  {superUser && (
                    <Button
                      sx={{height: '40px', mr: 0.5}}
                      onClick={() => setIsAddOrganizationDialogOpen(true)}
                      bttype="primary"
                      startIcon={<DomainAddIcon />}
                    >
                      Tilføj organisation
                    </Button>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        );

      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '1rem',
            margin: 0.5,
          }}
        >
          <Box>
            <Box sx={{display: 'flex'}}>
              {superUser && (
                <Box sx={{display: 'flex'}}>
                  <Box>
                    {superUser && (
                      <Button
                        sx={{height: '40px', mr: 0.5}}
                        onClick={() => setIsAddOrganizationDialogOpen(true)}
                        bttype="primary"
                        startIcon={<DomainAddIcon />}
                      >
                        Tilføj organisation
                      </Button>
                    )}
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
          <Box sx={{display: 'flex', gap: '0.5rem'}}>
            <MRT_GlobalFilterTextField table={table} />
            <MRT_ToggleFiltersButton table={table} />
            <MRT_ShowHideColumnsButton table={table} />
          </Box>
        </Box>
      );
    },

    muiTableBodyRowProps: ({row}) => ({
      onClick: () => {
        handleRowClick(row);
      },
      sx: {
        cursor: 'pointer', //you might want to change the cursor too when adding an onClick
      },
    }),

    enableBottomToolbar: type === TableTypes.TABLE ? true : false,
  };
  const [tableState] = useStatefullTableAtom<OrgDetails>('orgs_table', {});
  const table = useTable<OrgDetails>(columns, data, options, tableState, TableTypes.TABLE);

  return (
    <>
      <Container
        sx={{
          my: 0,
          mx: 0,
          paddingRight: '0em !important',
          paddingLeft: '0em !important',
          width: '100%',
          maxWidth: '100% !important',
          height: '100%',
        }}
      >
        <AddOrganizationDialog
          open={isAddOrganizationDialogOpen}
          setOpen={setIsAddOrganizationDialogOpen}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: tableHeight,
            minHeight: '420px',
          }}
        >
          <MaterialReactTable table={table} />
        </Box>
      </Container>
    </>
  );
};

export default OrganizationsTable;
