import {z} from 'zod';

import {Role} from '~/state/store';

export const appBarHeight = '64px';
export const activateProjects = false;
export const activateUnits = false;
export const drawerWidth = 240;

// Zod Validation
export const cvr = z
  .string({required_error: 'CVR er påkrævet'})
  .min(8, 'CVR skal være 8 tegn langt')
  .max(8, 'CVR skal være 8 tegn langt')
  .refine((value: any) => !isNaN(Number(value)), {message: 'CVR skal være et tal'});
export const name = z
  .string({required_error: 'Navn er påkrævet'})
  .min(2, 'Navn skal være længere end 2 tegn')
  .max(50, 'Navn må ikke være længere end 50 tegn');
export const address = z
  .string({required_error: 'Adresse er påkrævet'})
  .min(2, 'Addresse skal være længere end 2 tegn');
export const zip = z
  .string({required_error: 'Postnummer er påkrævet'})
  .length(4, 'Postnummer skal være præcis 4 tegn langt');
export const city = z
  .string({required_error: 'By er påkrævet'})
  .min(2, 'By skal være længere end 2 tegn');
export const country = z
  .string({required_error: 'Land er påkrævet'})
  .min(2, 'Land skal være længere end 2 tegn');
export const website = z
  .string({required_error: 'Hjemmeside er påkrævet'})
  .min(2, 'Hjemmeside skal være længere end 2 tegn')
  .optional()
  .nullish();
export const first_name = z
  .string({required_error: 'Fornavn er påkrævet'})
  .min(2, 'Navn skal være længere end 2 tegn')
  .max(50, 'Navn må ikke være længere end 50 tegn');
export const last_name = z
  .string()
  .min(2, 'Navn skal være længere end 2 tegn')
  .max(50, 'Navn må ikke være længere end 50 tegn')
  .optional();
export const mobile = z
  .string()
  .regex(/^[0-9]{8}$/, 'Telefonnummer skal være 8 tal')
  .optional()
  .or(z.literal(''));
export const password = z
  .string({required_error: 'Password is required'})
  .min(8, 'Password must be at least 8 characters long');
export const passwordField = z
  .string({required_error: 'Kodeord er påkrævet'})
  .min(8, 'Kodeord skal være længere end 8 tegn')
  .max(24, 'Kodeord må ikke være længere end 24 tegn')
  .superRefine((value, ctx) => {
    const hasCapital = /[A-Z]/.test(value);
    const hasSmall = /[a-z]/.test(value);
    const hasNumber = /\d/.test(value);
    let message: string | null = null;
    const specialReg = /[!"#¤%&/()=?@£$€{}|]/g;
    const hasUnallowedSpecialChar = specialReg.test(value);
    // const hasNonUnicodeChar = /[^\x00-\x7F]/.test(value);
    if (!(hasCapital && hasSmall && hasNumber)) {
      message = 'Kodeord skal indeholde mindst et stort og et lille bogstav og et tal';
    }
    if (hasUnallowedSpecialChar) {
      message = `Kodeord må ikke indeholde ${value.match(specialReg)}`;
    }
    if (message) {
      ctx.addIssue({message, code: z.ZodIssueCode.custom});
    }
  });

export const email = z.string({required_error: 'Email er påkrævet'}).email('Ugyldig email');
export const role = z.nativeEnum(Role);
export const organization_id = z.string({required_error: 'Valg af en organisation er påkrævet'});
export const newsletter = z.boolean().optional().default(false);
export const att = z.string({required_error: 'Ansvarsperson er påkrævet'});
export const ean = z
  .string()
  .length(13, 'EAN skal være præcis 13 tegn langt')
  .optional()
  .nullable();
export const entity_id = z.string();

// New Zod Schemas
