import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import {Dispatch, SetStateAction, useState} from 'react';
import {useParams, Link, useNavigate} from 'react-router-dom';

import Button from '~/components/Button';
import LinearLoading from '~/components/LineLoader';
import {SwitchLicensingType, useSwitchLicensingMutation} from '~/hooks/queries/licenses';
import {authStore} from '~/state/store';

type ConfirmAdditionalLicensingDialogProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  newPremiumUsers: number;
  licenseChanges: SwitchLicensingType;
  showPageString: string;
  setIsEditing?: Dispatch<SetStateAction<boolean>>;
};

const ConfirmAdditionalLicensingDialog = ({
  open,
  setOpen,
  newPremiumUsers,
  licenseChanges,
  showPageString,
  setIsEditing,
}: ConfirmAdditionalLicensingDialogProps) => {
  const switchLicensingMutation = useSwitchLicensingMutation();
  const {orgId} = useParams();
  const userOrgId = authStore((state) => state.org_id);
  const org_id = orgId || userOrgId || '';
  const [isMutating, setIsMutating] = useState(false);
  const navigate = useNavigate();
  const handleConfirm = () => {
    setIsMutating(true);
    switchLicensingMutation.mutate(
      {
        licenseChanges: licenseChanges.licenseChanges,
        org_id: org_id,
      },
      {
        onSuccess: () => {
          setOpen(false);
          setIsMutating(false);
          setIsEditing && setIsEditing(false);
        },
        onError: (error) => {
          setIsMutating(false);
        },
      }
    );
  };

  const invalidInvoice = (
    <Dialog
      open={open}
      onClose={(reason) => {
        if (reason === 'backdropClick') {
          return;
        }
        setOpen(false);
        setIsEditing && setIsEditing(false);
      }}
      PaperProps={{
        sx: {
          borderRadius: 4,
          padding: 2,
          paddingBottom: 1,
          boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
        },
      }}
    >
      <DialogTitle>Mangler faktureringsoplysninger</DialogTitle>
      <DialogContent sx={{paddingBottom: 0}}>
        <DialogContentText>
          Der er ikke angivet betalingsinformation for denne organisation.<br></br> Gå til
          <Link to={`/organizations/${org_id}/edit`}> faktureringsoplysninger</Link> for at angive
          oplysninger.
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{marginX: 2}}>
        <Button
          bttype="tertiary"
          onClick={() => {
            setOpen(false);
            setIsEditing && setIsEditing(false);
          }}
        >
          Annuller
        </Button>
        <Button
          bttype="primary"
          onClick={() => navigate(`/organizations/${org_id}/edit`)}
          sx={{
            mt: 2,
            mb: 2,
          }}
        >
          Gå til faktureringsoplysninger
        </Button>
      </DialogActions>
    </Dialog>
  );

  const validInvoice = (
    <Dialog
      open={open}
      onClose={(reason) => {
        if (reason === 'backdropClick') {
          return;
        }
        setOpen(false);
      }}
      PaperProps={{
        sx: {
          borderRadius: 4,
          padding: 2,
          paddingBottom: 1,
          boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
        },
      }}
    >
      <DialogTitle>Køb yderligere licenser</DialogTitle>
      <DialogContent sx={{paddingBottom: 0}}>
        <DialogContentText mb={2}>
          For at kunne tildele premium licenser til brugere for denne organisation, skal du købe
          yderligere licenser. Du har valgt at tildele {newPremiumUsers} yderligere premium licenser
          til denne organisation. Er du sikker på at du vil fortsætte? Der vil blive sendt en
          faktura til denne organisation.
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{marginX: 2}}>
        {isMutating ? (
          <LinearLoading width="large" height="large" color="primary" />
        ) : (
          <>
            <Button bttype="tertiary" onClick={() => setOpen(false)}>
              Annuller
            </Button>
            <Button
              bttype="primary"
              onClick={handleConfirm}
              sx={{
                mt: 2,
                mb: 2,
              }}
            >
              Bekræft
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
  if (!open) return;

  return showPageString == 'validInvoice' ? validInvoice : invalidInvoice;
};

export default ConfirmAdditionalLicensingDialog;
