import {Box, Divider, Paper, Typography} from '@mui/material';

import {useUsers} from '~/hooks/queries/users';
import useBreakpoints from '~/hooks/useBreakpoints';
import {TableTypes} from '~/hooks/useTable';

import UsersTable from './UsersTable';

const Users = () => {
  const {data} = useUsers();
  const {isMobile} = useBreakpoints();
  return (
    <Paper
      elevation={4}
      className="paperssssssssssss"
      sx={{
        justifyContent: 'center',
        padding: 0,
        width: '100%',
        maxWidth: '100%',
        minWidth: '200px',
        '.MuiPaper-root': {
          minWidth: '200px',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingX: isMobile ? 0 : 2,
          paddingY: isMobile ? 1 : 2,
          paddingBottom: isMobile ? 1 : 0,
        }}
      >
        <Typography variant="h5" fontWeight={900}>
          Brugere
        </Typography>
      </Box>
      <Divider
        sx={{
          width: '100%',
          borderBottomWidth: '2px',
          borderBottomColor: '#00786d',
          marginTop: isMobile ? 0 : '1em',
        }}
      />
      <UsersTable data={data} showOrgName showInvite={false} type={TableTypes.TABLE} />
    </Paper>
  );
};

export default Users;
