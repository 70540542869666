import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import {Controller} from 'react-hook-form';

import {OrgDetails} from '~/hooks/queries/organizations';

interface OrganizationDropdownProps {
  control: any;
  organizations: OrgDetails[] | undefined;
}

const OrganizationDropdown: React.FC<OrganizationDropdownProps> = ({control, organizations}) => {
  return (
    <Controller
      name="organization_id"
      control={control}
      render={({field: {name, onChange, value, onBlur, ref}}) => {
        // console.log('field', field);
        return (
          <Autocomplete
            id="organization_id"
            ref={ref}
            value={organizations?.find((org) => org.id === value) || null}
            onChange={(_event, value) => {
              onChange(value?.id || '');
            }}
            onBlur={onBlur}
            options={organizations || []}
            getOptionLabel={(option) => option.name + ' - ' + option.address}
            renderInput={(params) => (
              <TextField
                {...params}
                name={name}
                label="Organisation"
                required
                fullWidth
                sx={{mb: 2}}
              />
            )}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                <span>
                  {option.name} - {option.address}
                </span>
              </li>
            )}
            // onChange={(_event, value) => {
            //   setInternalValue(value as OrgDetails);
            // }}
            // onBlur={() => {
            //   setViewedOrg(internalValue);
            //   setValue('organization_id', internalValue?.id || ''); // You can change this depending on the value in the form
            // }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
        );
      }}
    />
  );
};

export default OrganizationDropdown;
