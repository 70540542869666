import {Button, Typography, Box} from '@mui/material';
import React from 'react';

interface CompanyLicenseProps {
  setPage: React.Dispatch<
    React.SetStateAction<
      'licenseManagement' | 'firstIndividualLicense' | 'secondIndividualLicense' | 'companyLicense'
    >
  >;
}

const CompanyLicense = ({setPage}: CompanyLicenseProps) => {
  return (
    <Box p={3}>
      <Typography variant="h5" gutterBottom>
        Individuel licenshåndtering
      </Typography>
      <Typography variant="body1" paragraph>
        Ved individuel licenshåndtering betaler du for hver enkelt aktiv licens. Én premium licens
        koster 2400 kr.
      </Typography>
      <Box display="flex" flexDirection="column" gap={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setPage('firstIndividualLicense')}
        >
          Opret ny licens
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setPage('secondIndividualLicense')}
        >
          Vælg bruger
        </Button>
        <Button variant="contained" color="secondary" onClick={() => setPage('licenseManagement')}>
          Fortsæt
        </Button>
      </Box>
    </Box>
  );
};

export default CompanyLicense;
