import {Box} from '@mui/material';
import React, {useState, useRef} from 'react';

import CompanyLicense from './introductionPages/CompanyLicense';
import FirstIndividualLicense from './introductionPages/FirstIndividualLicense';
import LicenseManagement from './introductionPages/LicenseManagement';
import SecondIndividualLicense from './introductionPages/SecondIndividualLicense';

const LicenseIntroduction = () => {
  const [page, setPage] = useState<
    'licenseManagement' | 'firstIndividualLicense' | 'secondIndividualLicense' | 'companyLicense'
  >('companyLicense');
  const dialogContainer = useRef(null);

  return (
    <Box
      ref={dialogContainer}
      sx={{
        width: '500px',
        height: '350px',
        borderRadius: 4,
        padding: '48px',
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
      }}
    >
      {page === 'licenseManagement' && <LicenseManagement setPage={setPage} />}
      {page === 'firstIndividualLicense' && <FirstIndividualLicense setPage={setPage} />}
      {page === 'secondIndividualLicense' && <SecondIndividualLicense setPage={setPage} />}
      {page === 'companyLicense' && <CompanyLicense setPage={setPage} />}
    </Box>
  );
};

export default LicenseIntroduction;
