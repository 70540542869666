import {Paper, Divider} from '@mui/material';

import AllUnitsTable from '~/pages/units/AllUnitsTable';

const Units = () => {
  return (
    <Paper elevation={4} sx={{justifyContent: 'center', margin: 2, padding: 0}}>
      <Divider
        sx={{
          width: '100%',
          borderBottomWidth: '2px',
          borderBottomColor: '#00786d',
          marginTop: '1em',
        }}
      />
      <AllUnitsTable />
    </Paper>
  );
};

export default Units;
