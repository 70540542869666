import {Box} from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import {useState} from 'react';
import {Route, Routes, Navigate} from 'react-router-dom';

import useBreakpoints from '~/hooks/useBreakpoints';
import Navbar from '~/NavBar';
import VerifiedPage from '~/pages/login/VerifiedPage';
import EditOrganization from '~/pages/organizations/EditOrganization';
import Organizations from '~/pages/organizations/Organizations';
import OrganizationPage from '~/pages/organizations/OrganizationsPage';
import Projects from '~/pages/projects/Projects';
import Units from '~/pages/units/Units';
import UserDetails from '~/pages/users/UserDetails';
import Users from '~/pages/users/Users';
import SideDrawer, {drawerWidth} from '~/SideDrawer';
import {authStore} from '~/state/store';

import LicenseIntroduction from '../licences/LicenseIntroduction';

import NotFound from './NotFound';

const ManagementPage = () => {
  const [openSideDrawer, setOpenSideDrawer] = useState(false);
  const {org_id, superUser} = authStore();
  const {isMobile, isDesktop} = useBreakpoints();
  if (!org_id) {
    return (
      <Box display="flex">
        <CssBaseline />
        <Navbar setOpenSideDrawer={setOpenSideDrawer} />
        <Box component="main" sx={{flexGrow: 1, pt: '80px'}}>
          <Routes>
            <Route path="/" element={<UserDetails isProfile />} />
            <Route path="/profile" element={<UserDetails isProfile />} />
            <Route path="*" element={<UserDetails />} />
          </Routes>
        </Box>
      </Box>
    );
  }
  return (
    <Box display="flex">
      <CssBaseline />
      <Navbar setOpenSideDrawer={setOpenSideDrawer} />
      {superUser && <SideDrawer open={openSideDrawer} setOpen={setOpenSideDrawer} />}
      <Box
        component="main"
        className="main"
        sx={{
          flexGrow: 1,
          pt: isMobile ? '68px' : '75px',
          maxWidth: isDesktop && superUser ? `calc(98% - ${drawerWidth}px)` : '98%',
          minWidth: `calc(98% - ${drawerWidth}px)`,
          mx: 'auto',
        }}
      >
        <Routes>
          <Route path="/" element={<OrganizationPage key="own" />} />
          <Route path="/organizations" element={<Organizations />} />
          <Route path="/organizations/:orgId" element={<OrganizationPage key="other" />} />
          <Route path="/organizations/:orgId/edit" element={<EditOrganization />} />
          <Route path="/users" element={<Users />} />
          <Route path="/profile" element={<UserDetails isProfile />} />
          <Route path="/users/:userId" element={<UserDetails />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/units" element={<Units />} />
          <Route path="/login" element={<Navigate to="/profile" />} />
          <Route path="/register" element={<Navigate to="/profile" />} />
          <Route path="introduction" element={<LicenseIntroduction />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Box>
    </Box>
  );
};

export default ManagementPage;
