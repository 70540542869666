import {Box, Typography, Divider} from '@mui/material';

export enum TableHeaderType {
  User = 'user',
  Project = 'project',
  Unit = 'unit',
  Organization = 'organization',
}

const unitTableHeader = (
  <Box sx={{display: 'flex', alignItems: 'center', paddingX: 2, paddingTop: 2}}>
    <Typography variant="h5" width={'165px'} fontWeight={900}>
      Udstyr
    </Typography>
    <Divider
      orientation="vertical"
      flexItem
      sx={{margin: '0 1em', borderRightColor: '#00786d', borderRightWidth: '2px'}}
    />

    <Typography variant="subtitle2" marginTop={1}>
      Denne side viser alle udstyrsenheder.
      <p></p>
      Opret en ny udstyrsenhed ved at trykke på knappen &quot;Opret projekt&quot;, eller tryk på en
      udstyrsenhed for at læse mere eller redigere.
    </Typography>
  </Box>
);

const projectTableHeader = (
  <Box sx={{display: 'flex', alignItems: 'center', paddingX: 2, paddingTop: 2}}>
    <Typography variant="h5" width={'165px'} fontWeight={900}>
      Projekter
    </Typography>
    <Divider
      orientation="vertical"
      flexItem
      sx={{margin: '0 1em', borderRightColor: '#00786d', borderRightWidth: '2px'}}
    />

    <Typography variant="subtitle2" marginTop={1}>
      Denne side viser alle projekter.
      <p></p>
      Opret et nyt projekt ved at trykke på knappen &quot;Opret projekt&quot;, eller tryk på et
      projekt for at læse mere eller redigere.
    </Typography>
  </Box>
);

const userTableHeader = (
  <Box sx={{display: 'flex', alignItems: 'center', paddingX: 2, paddingTop: 2}}>
    <Typography variant="h5" width={'165px'} fontWeight={900}>
      Brugere
    </Typography>
    <Divider
      orientation="vertical"
      flexItem
      sx={{margin: '0 1em', borderRightColor: '#00786d', borderRightWidth: '2px'}}
    />

    <Typography variant="subtitle2" marginTop={1}>
      Denne side viser alle brugere.
      <p></p>
      Opret en ny bruger ved at trykke på knappen &quot;Opret bruger&quot;, eller tryk på en bruger
      for at læse mere eller redigere.
    </Typography>
  </Box>
);

const organizationTableHeader = (
  <Box sx={{display: 'flex', alignItems: 'center', paddingX: 2, paddingTop: 2}}>
    <Typography variant="h5" width={'165px'} fontWeight={900}>
      Organisationer
    </Typography>
    <Divider
      orientation="vertical"
      flexItem
      sx={{margin: '0 1em', borderRightColor: '#00786d', borderRightWidth: '2px'}}
    />

    <Typography variant="subtitle2" marginTop={1}>
      Denne side viser alle organisationer.
      <p></p>
      Du kan oprette en organisation eller tryk på en organisation for at læse mere eller redigere.
    </Typography>
  </Box>
);

export type TableHeaderTextProps = {
  tableType: TableHeaderType;
};

export const TableHeaderText: React.FC<TableHeaderTextProps> = ({tableType}) => {
  let content;

  switch (tableType) {
    case TableHeaderType.Unit:
      content = unitTableHeader;
      break;
    case TableHeaderType.Project:
      content = projectTableHeader;
      break;
    case TableHeaderType.User:
      content = userTableHeader;
      break;
    case TableHeaderType.Organization:
      content = organizationTableHeader;
      break;
    default:
      content = null;
  }

  return content;
};
