import React from 'react';

interface LicenseManagementProps {
  setPage: React.Dispatch<
    React.SetStateAction<
      'licenseManagement' | 'firstIndividualLicense' | 'secondIndividualLicense' | 'companyLicense'
    >
  >;
}

const LicenseManagement = ({setPage}: LicenseManagementProps) => {
  return (
    <div>
      <h1>First Individual License</h1>
      <p>Welcome to the first individual license page.</p>
    </div>
  );
};

export default LicenseManagement;
