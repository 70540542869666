import {Edit} from '@mui/icons-material';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
// import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import {Card, CardContent, Divider, Typography, Box, Link} from '@mui/material';
import moment from 'moment';
import {useState} from 'react';
import {useParams} from 'react-router-dom';

import Button from '~/components/Button';
import LinearLoading from '~/components/LineLoader';
import {useInvoiceDetails, useLicenseInfo} from '~/hooks/queries/licenses.tsx';
import {useOrg} from '~/hooks/queries/organizations';
// import {useOrgProjects, useOrgUnits} from '~/hooks/queries/permissions';
import {useOrgUsers} from '~/hooks/queries/users';
import useBreakpoints from '~/hooks/useBreakpoints';
import EditOrganizationDialog from '~/pages/organizations/EditOrganizationDialog';
import {authStore} from '~/state/store';

import LicenseIntroductionDialog from '../licences/LicenseIntroductionDialog';

import EditLicensingDialog from './EditLicensingDialog';
import {EditVerifiedDomainDialog} from './EditVerifiedDomainDialog';

const EditOrganization = () => {
  const params = useParams();
  const orgId = params.orgId;
  const {superUser, org_id} = authStore();
  const [editOrgDialog, setEditOrgDialog] = useState(false);
  const [editLicensingDialog, setEditLicensingDialog] = useState(false);
  const [editVerifiedDomainDialog, setEditVerifiedDomainDialog] = useState(false);
  const [licenseIntroductionDialog, setLicenseIntroductionDialog] = useState(false);

  const {data: orgViewed, isPending} = useOrg(orgId ?? '');
  //const {data: orgProjects} = useOrgProjects(orgId ?? '');
  // const {data: orgUnits} = useOrgUnits(orgId ?? '');
  const {data: orgUsers} = useOrgUsers(orgId ?? '');
  const {data: invoiceDetails} = useInvoiceDetails(orgId ?? '');
  const isAdminOfOrg = orgViewed?.id === org_id;
  const hasEditRights = isAdminOfOrg || superUser;
  const {data: licenseInfo, isLoading} = useLicenseInfo(orgId ?? '');
  const {isMobile} = useBreakpoints();
  return (
    <Box margin={0} marginTop={0}>
      <EditOrganizationDialog
        open={editOrgDialog}
        setOpen={setEditOrgDialog}
        organization={orgViewed}
      />
      <EditVerifiedDomainDialog
        open={editVerifiedDomainDialog}
        setOpen={setEditVerifiedDomainDialog}
        baseDomain={
          orgUsers
            ?.find((user) => user.org_role === 'ADMIN' || user.org_role === 'SUPERUSER')
            ?.email.split('@')[1] ?? ''
        }
      />
      {!isLoading && (
        <EditLicensingDialog
          open={editLicensingDialog}
          setOpen={setEditLicensingDialog}
          organization={orgViewed}
          invoiceDetails={invoiceDetails}
          setLicenseIntroductionDialog={setLicenseIntroductionDialog}
        />
      )}
      <LicenseIntroductionDialog
        open={licenseIntroductionDialog}
        setOpen={setLicenseIntroductionDialog}
      />
      <Card
        elevation={4}
        sx={{
          width: '100%',
          my: 1,
          mt: 0,
          display: 'flex',
          height: '100%',
          flexWrap: 'wrap',
          alignItems: 'start',
        }}
      >
        {!isPending ? (
          <>
            <CardContent
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'start',
                height: '100%',
                flexGrow: 1,
                width: '45%',
                minWidth: '275px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: isMobile ? 'start' : 'space-between',
                  width: '100%',
                  height: isMobile ? 'auto' : '660px',
                }}
              >
                <Box>
                  <Typography textAlign={'center'} variant="h5" sx={{mt: 1, fontWeight: 'bold'}}>
                    Virksomhedsinformation
                  </Typography>
                  {!isMobile && <Divider sx={{mt: 2}} />}
                  <Typography
                    variant="subtitle2"
                    sx={{mt: isMobile ? 3 : 1, fontWeight: 'bold', fontSize: '12px'}}
                  >
                    Virksomhedsnavn
                  </Typography>
                  <Typography variant="body2" sx={{fontSize: '12px'}}>
                    {' '}
                    {orgViewed?.name ?? ''}
                  </Typography>
                  <Typography variant="body2" sx={{mt: 1, fontWeight: 'bold', fontSize: '12px'}}>
                    Hjemmeside:
                  </Typography>{' '}
                  <Link href={`//${orgViewed?.website}`} target="_blank" rel="noopener noreferrer">
                    <Typography variant="body2" sx={{fontSize: '12px'}}>
                      {orgViewed?.website}
                    </Typography>{' '}
                  </Link>
                  <Typography variant="body2" sx={{mt: 1, fontWeight: 'bold', fontSize: '12px'}}>
                    CVR:
                  </Typography>
                  <Typography variant="body2" sx={{fontSize: '12px'}}>
                    {' '}
                    {orgViewed?.cvr}
                  </Typography>
                  <Typography variant="body2" sx={{mt: 1, fontWeight: 'bold', fontSize: '12px'}}>
                    Antal brugere:
                  </Typography>
                  <Typography variant="body2" sx={{fontSize: '12px'}}>
                    {' '}
                    {orgUsers?.length}
                  </Typography>
                  <Typography variant="body2" sx={{mt: 1, fontWeight: 'bold', fontSize: '12px'}}>
                    Antal administratorer:
                  </Typography>
                  <Typography variant="body2" sx={{fontSize: '12px'}}>
                    {
                      orgUsers?.filter(
                        (user) => user.org_role === 'ADMIN' || user.org_role === 'SUPERUSER'
                      ).length
                    }
                  </Typography>
                  <Typography variant="body2" sx={{mt: 1, fontWeight: 'bold', fontSize: '12px'}}>
                    Addresse:
                  </Typography>
                  <Typography variant="body2" sx={{fontSize: '12px'}}>
                    {' '}
                    {orgViewed?.address}, {orgViewed?.zip}, {orgViewed?.city}, {orgViewed?.country}
                  </Typography>
                  <Typography variant="body2" sx={{mt: 1, fontWeight: 'bold', fontSize: '12px'}}>
                    Oprettet:
                  </Typography>
                  <Typography variant="body2" sx={{fontSize: '12px'}}>
                    {moment(orgViewed?.created_at).format('LL')}
                  </Typography>
                  <Typography variant="body2" sx={{mt: 1, fontWeight: 'bold', fontSize: '12px'}}>
                    Sidst opdateret:
                  </Typography>
                  <Typography variant="body2" sx={{fontSize: '12px'}}>
                    {moment(orgViewed?.updated_at).format('LL')}
                  </Typography>
                  <Typography variant="body2" sx={{mt: 1, fontWeight: 'bold', fontSize: '12px'}}>
                    Beskyttet domæne:
                  </Typography>
                  {orgViewed?.verified_domain ? (
                    <Typography variant="body2" sx={{fontSize: '12px'}}>
                      {orgViewed?.verified_domain}
                    </Typography>
                  ) : (
                    <Box sx={{display: 'flex', gap: 1}}>
                      <Typography variant="body2" sx={{fontSize: '12px'}}>
                        Ingen domænebeskyttelse -
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{fontSize: '12px', color: 'primary.main'}}
                        onClick={() => setEditVerifiedDomainDialog(true)}
                        style={{cursor: 'pointer'}}
                        onMouseOver={(e) => (e.currentTarget.style.textDecoration = 'underline')}
                        onMouseOut={(e) => (e.currentTarget.style.textDecoration = 'none')}
                        onMouseDown={(e) => (e.currentTarget.style.fontWeight = 'bold')}
                        onMouseUp={(e) => (e.currentTarget.style.fontWeight = 'normal')}
                      >
                        Beskyt dit email domæne her
                      </Typography>
                    </Box>
                  )}
                </Box>
                <Box>
                  {hasEditRights && (
                    <Button
                      sx={{mt: 2, width: '100%'}}
                      startIcon={<Edit />}
                      onClick={() => setEditOrgDialog(true)}
                      bttype="primary"
                    >
                      Rediger virksomhedsinformation
                    </Button>
                  )}
                </Box>
              </Box>
            </CardContent>
            <Divider
              orientation={isMobile ? 'horizontal' : 'vertical'}
              flexItem
              sx={{mx: isMobile ? '2em' : '0em', display: isMobile ? 'none' : 'block'}}
            />
            {isMobile && <Divider orientation="horizontal" sx={{mt: 2, width: 1}} />}
            <CardContent
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                height: '100%',
                flexGrow: 1,
                width: '45%',
                minWidth: '275px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: isMobile ? 'start' : 'space-between',
                  width: '100%',
                  height: isMobile ? 'auto' : '660px',
                }}
              >
                <Box>
                  <Typography
                    textAlign={'center'}
                    variant="h5"
                    sx={{mt: 1, mb: isMobile ? 2 : 0, fontWeight: 'bold'}}
                  >
                    Faktureringsoplysninger
                  </Typography>
                  {!isMobile && <Divider sx={{mt: 2}} />}
                  <Box
                    sx={{
                      width: '100%',
                      minWidth: '20px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: licenseInfo ? 'space-between' : 'start',
                    }}
                  >
                    {licenseInfo && (
                      <>
                        <Box sx={{display: 'flex', width: '100%'}}>
                          <Box
                            sx={{
                              flexGrow: 1,
                              minWidth: '180px',
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'space-between',
                              pt: 0,
                            }}
                          >
                            <Box>
                              <Typography
                                variant="body2"
                                sx={{mt: 1, fontWeight: 'bold', fontSize: '12px'}}
                              >
                                Antal premium Licenser: &ensp; {licenseInfo?.premium_licences ?? 0}
                              </Typography>
                              <Typography
                                variant="body2"
                                sx={{mt: 1, fontWeight: 'bold', fontSize: '12px'}}
                              >
                                Antal betalte licenser: &ensp; {licenseInfo?.paid_for_licences ?? 0}
                              </Typography>

                              <Typography
                                variant="body2"
                                sx={{mt: 1, fontWeight: 'bold', fontSize: '12px'}}
                              >
                                Antal tilgængelige licenser: &ensp;{' '}
                                {licenseInfo?.available_licenses ?? 0}
                              </Typography>

                              <Typography
                                variant="body2"
                                sx={{mt: 1, fontWeight: 'bold', fontSize: '12px'}}
                              >
                                Antal anvendte licenser: &ensp;
                                {licenseInfo?.premium_licences - licenseInfo?.available_licenses}
                              </Typography>
                            </Box>
                            <Box mt={2}>
                              <Typography variant="body2" sx={{fontSize: '12px'}}>
                                Gyldig til: 1. Januar{' '}
                                {moment().add(1, 'year').startOf('year').format('YYYY')}
                              </Typography>
                            </Box>
                          </Box>

                          <Box
                            sx={{
                              minWidth: '160px',
                              width: licenseInfo ? '200px' : '200px',
                              mt: 1,
                              borderLeft: '1px solid black',
                              p: 0.5,
                              pl: 1,
                            }}
                          >
                            <>
                              <Typography
                                variant="subtitle2"
                                sx={{fontWeight: 600, textAlign: 'center', mb: 1}}
                              >
                                Betaling
                              </Typography>

                              {licenseInfo ? (
                                <>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      mt: 1.5,
                                      mb: 1.5,
                                    }}
                                  >
                                    <Typography variant="body2" sx={{fontSize: '12px'}}>
                                      Der betales for antallet af anvendte licenser ved næste
                                      fakturering
                                    </Typography>
                                  </Box>
                                  <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                                    <Typography variant="body2" sx={{fontSize: '12px'}}>
                                      Antal anvendte licenser:
                                    </Typography>
                                    <Typography variant="body2" sx={{fontSize: '12px'}}>
                                      {licenseInfo?.premium_licences -
                                        licenseInfo?.available_licenses}
                                    </Typography>
                                  </Box>

                                  <Typography variant="subtitle2" sx={{fontSize: '12px', mt: 1.5}}>
                                    Næste betalingsdato:
                                  </Typography>
                                  <Typography
                                    variant="subtitle2"
                                    sx={{fontWeight: 600, fontSize: '11px'}}
                                  >
                                    1. Januar{' '}
                                    {moment().add(1, 'year').startOf('year').format('YYYY')}
                                  </Typography>
                                </>
                              ) : (
                                <Box>
                                  <Typography
                                    variant="body2"
                                    sx={{fontSize: '12px', textAlign: 'center'}}
                                  >
                                    Din organisation har ikke angivet betalingsoplysninger og har
                                    derfor ingen betalinger i vente.
                                  </Typography>
                                </Box>
                              )}
                            </>
                          </Box>
                        </Box>

                        <Divider sx={{mt: 1}} />
                      </>
                    )}
                  </Box>
                  <Box>
                    <Box pt={1}>
                      <Typography variant="body2" sx={{fontSize: '12px', fontWeight: 600}}>
                        CVR:
                      </Typography>
                      <Typography variant="body2" sx={{fontSize: '12px'}}>
                        {invoiceDetails?.cvr}
                      </Typography>
                    </Box>
                    <Box pt={1}>
                      <Typography variant="body2" sx={{fontSize: '12px', fontWeight: 600}}>
                        EAN:
                      </Typography>
                      <Typography variant="body2" sx={{fontSize: '12px'}}>
                        {invoiceDetails?.ean}
                      </Typography>
                    </Box>
                    <Box pt={1}>
                      <Typography variant="body2" sx={{fontSize: '12px', fontWeight: 600}}>
                        Ansvarsperson:
                      </Typography>
                      <Typography variant="body2" sx={{fontSize: '12px'}}>
                        {invoiceDetails?.att}
                      </Typography>
                    </Box>
                    <Box pt={1}>
                      <Typography variant="body2" sx={{fontSize: '12px', fontWeight: 600}}>
                        Email:
                      </Typography>
                      <Typography variant="body2" sx={{fontSize: '12px'}}>
                        {invoiceDetails?.email}
                      </Typography>
                    </Box>

                    <Box pt={1}>
                      <Typography variant="body2" sx={{fontSize: '12px', fontWeight: 600}}>
                        Addresse:
                      </Typography>
                      <Typography variant="body2" sx={{fontSize: '12px'}}>
                        {invoiceDetails?.address}
                      </Typography>
                    </Box>
                    <Box pt={1}>
                      <Typography variant="body2" sx={{fontSize: '12px', fontWeight: 600}}>
                        Postnummer:
                      </Typography>
                      <Typography variant="body2" sx={{fontSize: '12px'}}>
                        {invoiceDetails?.zip}
                      </Typography>
                    </Box>
                    <Box pt={1}>
                      <Typography variant="body2" sx={{fontSize: '12px', fontWeight: 600}}>
                        By:
                      </Typography>
                      <Typography variant="body2" sx={{fontSize: '12px'}}>
                        {invoiceDetails?.city}
                      </Typography>
                    </Box>
                    <Box pt={1}>
                      <Typography variant="body2" sx={{fontSize: '12px', fontWeight: 600}}>
                        Land:
                      </Typography>
                      <Typography variant="body2" sx={{fontSize: '12px'}}>
                        {invoiceDetails?.country}
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                <Box>
                  {hasEditRights && (
                    <>
                      <Button
                        sx={{mt: 2, width: '100%'}}
                        startIcon={<AccountBalanceWalletIcon />}
                        onClick={() => setEditLicensingDialog(true)}
                        bttype="primary"
                      >
                        Rediger Faktureringsoplysninger
                      </Button>
                    </>
                  )}
                </Box>
              </Box>
            </CardContent>
          </>
        ) : (
          <Box sx={{margin: 'auto', height: '92vh', alignItems: 'center', display: 'flex'}}>
            <LinearLoading width="large" height="large" color="primary" />
          </Box>
        )}
      </Card>
    </Box>
  );
};

export default EditOrganization;
