import {Box} from '@mui/material';
import React, {useState, useRef} from 'react';

import {CvrData} from '~/fieldAPI';

import ConfirmOrganizationCreation from './signupPages/ConfirmOrganizationCreation';
import DecideOrgNoOrg from './signupPages/DecideOrgNoOrg';
import EnterCVR from './signupPages/EnterCVR';
import UserCreated from './signupPages/UserCreated';

interface CreateOrganisationDialogProps {
  backToRegister: () => void;
  createUser: () => void;
  userCreated: boolean;
  isMutating: boolean;
  mutationError: string | null;
}

const CreateOrganisationDialog = ({
  backToRegister,
  createUser,
  userCreated,
  isMutating,
  mutationError,
}: CreateOrganisationDialogProps) => {
  const [page, setPage] = useState<
    'decideOrgOrNoOrg' | 'enterCVR' | 'confirmOrg' | 'creationConfirmed'
  >('decideOrgOrNoOrg');
  const dialogContainer = useRef(null);
  const finishCreation = () => {
    setPage('creationConfirmed');
    createUser();
  };

  const [cvrData, setCvrData] = useState<null | CvrData>(null);

  return (
    <Box
      ref={dialogContainer}
      sx={{
        width: '500px',
        height: '350px',
        borderRadius: 4,
        padding: '48px',
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
      }}
    >
      {page === 'decideOrgOrNoOrg' && (
        <DecideOrgNoOrg
          page={page}
          setPage={setPage}
          backToRegister={backToRegister}
          finishCreation={finishCreation}
        />
      )}
      {page === 'enterCVR' && <EnterCVR page={page} setPage={setPage} setCvrData={setCvrData} />}
      {page === 'confirmOrg' && (
        <ConfirmOrganizationCreation
          page={page}
          setPage={setPage}
          cvrData={cvrData}
          setCvrData={setCvrData}
          finishCreation={finishCreation}
        />
      )}
      {page === 'creationConfirmed' && (
        <UserCreated
          page={page}
          setPage={setPage}
          backToRegister={backToRegister}
          userCreated={userCreated}
          isMutating={isMutating}
          mutationError={mutationError}
        />
      )}
    </Box>
  );
};

export default CreateOrganisationDialog;
