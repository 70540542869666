import {Fade, Box, Typography} from '@mui/material';
import React from 'react';
import {useFormContext} from 'react-hook-form';

import Button from 'src/components/Button';
import type {RegisterFormValues} from '~/pages/login/Register';

interface DomainOrgFoundProps {
  page: 'domainOrgFound' | 'domainAccepted';
  setPage: React.Dispatch<React.SetStateAction<'domainOrgFound' | 'domainAccepted'>>;
  domainOrg: string;
  backToRegister: () => void;
  createUser: () => void;
}

const DomainOrgFound = ({
  page,
  setPage,
  domainOrg,
  backToRegister,
  createUser,
}: DomainOrgFoundProps) => {
  const {setValue} = useFormContext<RegisterFormValues>();

  return (
    <Fade in={page == 'domainOrgFound'}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
        }}
      >
        <Box>
          <Typography variant="h5">Bliv en del af {domainOrg}!</Typography>
        </Box>

        <Box>
          <div>
            <Typography>
              Emailen du har indtastet er forbundet med {domainOrg}. Ønsker du at blive en del af
              organisationen?
            </Typography>
          </div>
        </Box>
        <Box sx={{display: 'flex', justifyContent: 'end', gap: 2, mt: 4}}>
          <Button
            onClick={() => {
              backToRegister();
            }}
            bttype="tertiary"
          >
            Afvis
          </Button>
          <Button
            onClick={() => {
              setPage('domainAccepted');
              setValue('join_domain', true);
              createUser();
            }}
            bttype="primary"
          >
            Accepter
          </Button>
        </Box>
      </Box>
    </Fade>
  );
};

export default DomainOrgFound;
