import AccountTreeIcon from '@mui/icons-material/AccountTree';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import GroupIcon from '@mui/icons-material/Group';
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  List,
  Typography,
} from '@mui/material';
import React from 'react';
import {useNavigate, useLocation} from 'react-router-dom';

import {authStore} from 'src/state/store';
import {activateProjects, activateUnits} from '~/consts';
import {useOrg} from '~/hooks/queries/organizations';

type AuthenticatedRoutesProps = {
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;
};

type RouteItem = {
  name: string;
  route: string;
  icon: JSX.Element;
};

export const AuthenticatedRoutes = ({setOpenDrawer}: AuthenticatedRoutesProps) => {
  const location = useLocation();

  const {org_id, superUser} = authStore();
  const {data} = useOrg(org_id);
  const org_name = data?.name;
  const navigate = useNavigate();
  const adminRoutes = [
    {
      name: `${org_name} | Overblik`,
      route: ``,
      icon: <CorporateFareIcon style={{fill: 'white'}} />,
    },
  ];

  const superRoutes = [
    {
      name: `${org_name} | Overblik`,
      route: ``,
      icon: <CorporateFareIcon style={{fill: 'white'}} />,
    },
    {
      name: 'Organisationer',
      route: 'organizations',
      icon: <CorporateFareIcon style={{fill: 'white'}} />,
    },
    {
      name: 'Brugere',
      route: 'users',
      icon: <GroupIcon style={{fill: 'white'}} />,
    },
    ...(activateProjects
      ? [
          {
            name: 'Projekter',
            route: 'projects',
            icon: <AccountTreeIcon style={{fill: 'white'}} />,
          },
        ]
      : []),
    ...(activateUnits
      ? [
          {
            name: 'Udstyr',
            route: 'units',
            icon: <AccountTreeIcon style={{fill: 'white'}} />,
          },
        ]
      : []),
  ];

  const routes = superUser ? superRoutes : adminRoutes;
  const activeRoute = location.pathname;

  const handleClick = (item: RouteItem) => {
    navigate(`/${item.route}`);
    setOpenDrawer(false);
  };

  return (
    <List>
      {routes.map((item) => (
        <ListItem
          key={item.route}
          disablePadding
          sx={{
            backgroundColor:
              activeRoute === `/${item.route}` ? 'rgba(255, 255, 255, 0.3)' : 'transparent',
            fontFamily: 'Open Sans',
          }}
          onClick={() => handleClick(item)}
        >
          <ListItemButton
            sx={{
              '&& .MuiTouchRipple-rippleVisible': {
                animationDuration: '800ms',
              },
            }}
            disableRipple
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText
              sx={{fontFamily: 'Open Sans'}}
              primary={<Typography variant="h6">{item.name}</Typography>}
            />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
};

export default AuthenticatedRoutes;
