import {PersonAdd} from '@mui/icons-material';
import {Box, Container, useMediaQuery} from '@mui/material';
import {
  MRT_ColumnDef,
  MRT_GlobalFilterTextField,
  MRT_Row,
  MRT_ShowHideColumnsButton,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFiltersButton,
  MRT_ToggleFullScreenButton,
  MaterialReactTable,
} from 'material-react-table';
import {MRT_Localization_DA} from 'material-react-table/locales/da';
import React, {useMemo} from 'react';

import Button from '~/components/Button';
import {Unit} from '~/hooks/queries/permissions';
import {authStore} from '~/state/store';

interface UnitsTableProps {
  unitsData: Unit[] | undefined;
}

const OnlyOrgUnitsTable = ({unitsData}: UnitsTableProps) => {
  const columns: MRT_ColumnDef<Unit>[] = useMemo(
    () => [
      {
        accessorKey: 'calypso_id',
        header: 'Calypso ID',
        // The purpose of the cell is to exchange the calypso id with the terminal id if calypso id is 0.
        Cell: ({row}: {row: MRT_Row<Unit>}) => {
          const calypso_id = row.original.calypso_id;
          if (calypso_id == '0') {
            return row.original.terminal_id;
          }
          return calypso_id;
        },
      },
      {
        accessorKey: 'projectno',
        header: 'Projektnr.',
      },
      {
        accessorKey: 'startdate',
        header: 'Startdato',
      },
      /*       {
                    accessorKey: 'permission',
                    header: 'Tilladelse', --> not necessary since superuser can see all projects
                    maxSize: 110,
                  } */
    ],
    []
  );

  const smallScreen = useMediaQuery('(max-width: 700px)');
  const {adminAccess, superUser} = authStore();
  return (
    <>
      <Container
        sx={{
          my: 0,
          paddingRight: '0em !important',
          paddingLeft: '0em !important',
          width: '100%',
          maxWidth: '3000px !important',
        }}
      >
        <MaterialReactTable
          paginationDisplayMode="pages"
          muiPaginationProps={{
            size: 'small',
            showRowsPerPage: false,
            color: 'primary',
            shape: 'rounded',
            variant: 'outlined',
          }}
          enableColumnActions={false}
          columns={columns}
          data={unitsData ?? []}
          muiTablePaperProps={{
            elevation: 0,
          }}
          muiTableProps={{
            sx: {
              tableLayout: 'fixed',
              width: '100%',
              padding: 0,
              maxWidth: '3000px',
            },
          }}
          initialState={{
            density: 'compact',
            showGlobalFilter: true,
          }}
          renderTopToolbar={({table}) => (
            <>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  margin: 2,
                }}
              >
                {(adminAccess || superUser) && (
                  <Button sx={{height: '30px', mr: 1}} onClick={() => undefined} bttype="primary">
                    {smallScreen ? <PersonAdd /> : `Opret Udstyr`}
                  </Button>
                )}
                <MRT_GlobalFilterTextField
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '9999px',
                    },
                  }}
                  table={table}
                />{' '}
                <Box>
                  <MRT_ToggleFiltersButton table={table} />
                  <MRT_ShowHideColumnsButton table={table} />
                  <MRT_ToggleDensePaddingButton table={table} />
                  <MRT_ToggleFullScreenButton table={table} />
                </Box>
              </Box>
            </>
          )}
          localization={MRT_Localization_DA}
        />
      </Container>
    </>
  );
};

export default OnlyOrgUnitsTable;
