import React from 'react';

interface SecondIndividualLicenseProps {
  setPage: React.Dispatch<
    React.SetStateAction<
      'licenseManagement' | 'firstIndividualLicense' | 'secondIndividualLicense' | 'companyLicense'
    >
  >;
}

const SecondIndividualLicense = ({setPage}: SecondIndividualLicenseProps) => {
  return (
    <div>
      <h1>First Individual License</h1>
      <p>Welcome to the first individual license page.</p>
    </div>
  );
};

export default SecondIndividualLicense;
