import {Box, Typography} from '@mui/material';
import {Suspense, useEffect} from 'react';
import {ErrorBoundary} from 'react-error-boundary';
import {useNavigate} from 'react-router-dom';

import ManagementPage from '~/pages/management/ManagementPage';

import {apiClient} from './apiClient';
import Button from './components/Button';
import {usePermissions} from './hooks/queries/permissions';
import LoadingSkeleton from './LoadingSkeleton';
import NavBar from './NavBar';
import {authStore} from './state/store';
import UnAuntenticatedApp from './UnauthenticatedApp';

function App() {
  const [authenticated, setAuthenticated, setLoginExpired, setAuthorization] = authStore(
    (state) => [
      state.authenticated,
      state.setAuthenticated,
      state.setLoginExpired,
      state.setAuthorization,
    ]
  );

  const {refetch} = usePermissions();
  const navigate = useNavigate();
  useEffect(() => {
    apiClient
      .get('/auth/me/secure')
      .then((res) => {
        setAuthorization(res.data);
        refetch();
        setAuthenticated(true);
        setLoginExpired(false);
      })
      .catch(() => {
        setAuthenticated(false);
        setLoginExpired(true);
      });
  }, []);

  if (!authenticated) {
    return (
      <>
        <NavBar />
        <UnAuntenticatedApp />
      </>
    );
  }

  return (
    <ErrorBoundary
      FallbackComponent={() => (
        <>
          <NavBar />
          <Box
            sx={{
              height: '88vh',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              gap: 4,
              alignItems: 'center',
            }}
          >
            <Typography variant="h4" component="h1" sx={{textAlign: 'center'}}>
              Noget gik galt.
            </Typography>
            <Button bttype="primary" onClick={() => navigate(-1)}>
              Gå tilbage
            </Button>
          </Box>
        </>
      )}
      onError={(error) => {
        if (error.message.includes('Failed to fetch dynamically imported module')) {
          // window.location.reload(true);
        }
      }}
    >
      <Suspense fallback={<LoadingSkeleton />}>
        <ManagementPage />
      </Suspense>
    </ErrorBoundary>
  );
}

export default App;
