import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Save from '@mui/icons-material/Save';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import {Box, Container} from '@mui/material';
import {
  MRT_ColumnDef,
  MRT_GlobalFilterTextField,
  MRT_Row,
  MRT_ShowHideColumnsButton,
  MRT_TableOptions,
  MRT_ToggleFiltersButton,
  MaterialReactTable,
} from 'material-react-table';
import {useEffect, useMemo, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {toast} from 'react-toastify';

import {checkInvoiceDetails} from '~/api/functions';
import Button from '~/components/Button';
import LinearLoading from '~/components/LineLoader';
import {
  SwitchLicensingType,
  useLicenseInfo,
  useSwitchLicensingMutation,
} from '~/hooks/queries/licenses';
import {useOrgRole, usePermissionMutation} from '~/hooks/queries/permissions';
import {mapRoleEnumToString, mapRoleStringToEnum, UserType} from '~/hooks/queries/users';
import useBreakpoints from '~/hooks/useBreakpoints';
import {TableTypes, useTable} from '~/hooks/useTable';
import {useStatefullTableAtom} from '~/hooks/useTableState';
import {authStore} from '~/state/store';

import ConfirmAdditionalLicensingDialog from './ConfirmAdditionalLicensingDialog';
import GeneralAddUserDialog from './GeneralAddUserDialog';
import InviteUserDialog from './InviteUserDialog';

interface UsersTableProps {
  data: UserType[] | undefined;
  showOrgName?: boolean;
  showInvite?: boolean;
  type?: TableTypes;
  isEnterprise?: boolean;
}

const UsersTable = ({
  data,
  showOrgName = false,
  showInvite = true,
  type = TableTypes.TABLE,
  isEnterprise = false,
}: UsersTableProps) => {
  // Dialog states
  const [openInvite, setOpenInvite] = useState(false);
  const [openAddUser, setOpenAddUser] = useState(false);
  const [openAdditionalLicensing, setOpenAdditionalLicensing] = useState(false);
  const [contentTo, setContentTo] = useState('invalidInvoice');

  // Utility hooks
  const navigate = useNavigate();
  const [superUser, userOrgId, authedUserId] = authStore((state) => [
    state.superUser,
    state.org_id,
    state.user_id,
  ]);
  const {orgId} = useParams();
  // const userOrgId = authStore((state) => state.org_id);
  const org_id = orgId || userOrgId || '';
  const role = useOrgRole(org_id ?? '');
  const permissionMutation = usePermissionMutation();
  const switchLicensingMutation = useSwitchLicensingMutation();
  const {isTouch, isMobile} = useBreakpoints();
  const useMaxHeight = useBreakpoints().isShortHeight;
  const tableHeight =
    useMaxHeight && isMobile
      ? 'calc(100vh - 125px)'
      : useMaxHeight && !isMobile
        ? 'calc(100vh - 150px)'
        : !useMaxHeight && isMobile
          ? 'calc(100vh - 125px)'
          : 'calc(100vh - 150px)';
  const {data: licenseInfo} = useLicenseInfo(org_id);

  // Table states
  const [differenceInPremiumUsers, setDifferenceInPremiumUsers] = useState(0);
  const [editedUsers, setEditedUsers] = useState<Record<string, UserType>>({});
  const [premiumUsers, setPremiumUsers] = useState<string[]>([]);
  const [isEdited, setIsEdited] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const editPermissions = role === 'ADMIN' || superUser;

  const [originalLicenses, original_premium_users] = useMemo(() => {
    const originalLicenses = data?.map((user) => ({
      user_id: user.id,
      license: user.license || null,
    }));
    const original_premium_users = originalLicenses?.filter(
      (license) => license.license === 'INDIVIDUAL'
    );
    return [originalLicenses, original_premium_users];
  }, [data]);

  const needAdditionalLicensing =
    differenceInPremiumUsers > (licenseInfo?.available_licenses ?? 100); // if error send to backend to handle anyway

  // Check which state makes component render

  // Set premium users
  useEffect(() => {
    if (data) {
      const premiumUsers = data
        .filter((user) => user.license === 'INDIVIDUAL')
        .map((user) => user.id);
      setPremiumUsers(premiumUsers);
    }
  }, [data]);

  // Check if invoice details are valid
  useEffect(() => {
    checkInvoiceDetails(org_id).then((response) => {
      if (response != false) {
        setContentTo('validInvoice');
      } else {
        setContentTo('invalidInvoice');
      }
    });
  }, [org_id]);

  // Check if the user has added or removed premium users (for button state)
  useEffect(() => {
    if (original_premium_users) {
      setDifferenceInPremiumUsers(premiumUsers.length - original_premium_users.length);
      setIsEdited(
        original_premium_users.some((license) => !premiumUsers.includes(license.user_id)) ||
          premiumUsers.some(
            (user_id) => !original_premium_users.some((license) => license.user_id === user_id)
          )
      );
    }
    if (Object.keys(editedUsers).length > 0) {
      setIsEdited(true);
      return;
    }
  }, [original_premium_users, premiumUsers, editedUsers]);

  const columns = useMemo<MRT_ColumnDef<UserType>[]>(() => {
    return [
      ...(showOrgName
        ? [
            {
              accessorKey: 'organization_name',
              header: 'Organisation',
              size: 200,
              enableColumnResizing: true,
              enableEditing: false,
              Cell: ({row}: {row: MRT_Row<UserType>}) =>
                row.original.organization_name || 'Ingen organisation',
            },
          ]
        : []),
      {
        accessorKey: 'first_name',
        header: 'Fornavn',
        enableEditing: false,
        size: isTouch ? 145 : 150,
      },
      {
        accessorKey: 'last_name',
        header: 'Efternavn',
        enableEditing: false,
        size: isTouch ? 145 : 150,
      },
      {
        accessorKey: 'email',
        header: 'E-mail',
        size: isTouch ? 250 : 125,
        enableEditing: false,
      },

      {
        accessorKey: 'org_role',
        header: 'Rettighed',
        size: 150,
        editVariant: 'select',
        editSelectOptions: [...(superUser ? ['Superbruger'] : []), 'Administrator', 'Bruger'],
        enableEditing: (row) =>
          superUser ||
          (role === 'ADMIN' && row.original.org_role == 'USER') ||
          (role === 'ADMIN' && row.original.id == authedUserId),
        accessorFn: (row) => mapRoleEnumToString(row.org_role),
        muiTableBodyCellProps: () => ({
          onClick: (e) => {
            e.stopPropagation();
          },
          sx: {
            cursor: 'pointer',
            padding: 0,
          },
        }),
        muiEditTextFieldProps: ({row}) => ({
          select: true,
          sx: {
            padding: '0.0em',
            marginLeft: 1,
            '& .MuiSelect-select.MuiInputBase-input.MuiInput-input': {
              padding: isMobile ? 0 : 1,
              paddingRight: 2,
              fontSize: '0.875rem', // Modify the font size here
            },
            textOverflow: 'ellipsis',
            maxWidth: '175px',
          },
          onChange: (event) => {
            const value = mapRoleStringToEnum(event.target.value);
            setEditedUsers((prev) => {
              const newEditedUsers = {...prev};
              if (value === row.original.org_role) {
                delete newEditedUsers[row.original.id];
              } else {
                newEditedUsers[row.original.id] = {
                  ...row.original,
                  org_role: value,
                };
              }
              return newEditedUsers;
            });
          },
        }),
      },
      ...((role == 'ADMIN' || superUser) && type === TableTypes.SCROLLING // To avoid showcasing role in the general users table, since role depends on org.
        ? isEnterprise
          ? [
              {
                accessorKey: 'license',
                header: 'Licens',
                size: 50,
                enableEditing: false,
                Cell: () => 'Virksomhed',
              },
            ]
          : ([
              {
                accessorKey: 'license',
                header: 'Licens',
                size: 50,
                editVariant: 'select',
                editSelectOptions: isEnterprise ? ['Virksomhed'] : ['Gratis', 'Premium'],
                enableEditing: role == 'ADMIN' || superUser,
                muiTableBodyCellProps: () => ({
                  onClick: (e) => {
                    e.stopPropagation();
                  },
                }),
                muiEditTextFieldProps: ({row}) => ({
                  value: isEnterprise
                    ? 'Virksomhed'
                    : premiumUsers.includes(row.original.id)
                      ? 'Premium'
                      : 'Gratis',
                  select: true,
                  disabled: isEnterprise,
                  sx: {
                    padding: '0.0em',
                    '& .MuiSelect-select.MuiInputBase-input.MuiInput-input': {
                      padding: 1,
                      paddingRight: 2,
                      fontSize: '0.875rem', // Modify the font size here
                    },
                    textOverflow: 'ellipsis',
                    maxWidth: '175px',
                  },
                  onChange: (event) => {
                    const newValue = event.target.value as string; // Cast to string
                    const originalValue = originalLicenses?.find(
                      (license) => license.user_id === row.original.id
                    )?.license;

                    if (
                      (newValue === 'Gratis' && originalValue === null) ||
                      (newValue === 'Premium' && originalValue === 'INDIVIDUAL')
                    ) {
                      setPremiumUsers((prev) =>
                        prev.includes(row.original.id)
                          ? prev.filter((id) => id !== row.original.id)
                          : [...prev, row.original.id]
                      );
                    }

                    if (newValue === 'Premium' && originalValue === null) {
                      setPremiumUsers((prev) => [...prev, row.original.id]);
                    }

                    if (newValue === 'Gratis' && originalValue === 'INDIVIDUAL') {
                      setPremiumUsers((prev) => prev.filter((id) => id !== row.original.id));
                    }
                  },
                }),
              },
            ] as MRT_ColumnDef<UserType>[])
        : []),
      ...(superUser
        ? ([
            {
              accessorKey: 'mobile',
              header: 'Mobil',
              size: 120,
              enableEditing: false,
            },
            {
              accessorFn: (row) => (row.is_verified ? 'Ja' : 'Nej'),
              id: 'is_verified',
              header: 'Verificeret',
              size: 50,
              enableEditing: false,
            },

            {
              accessorFn: (row) => (row.newsletter ? 'Ja' : 'Nej'),
              id: 'newsletter',
              header: 'Nyhedsbrev',
              size: 50,
              enableEditing: false,
            },
          ] as MRT_ColumnDef<UserType>[])
        : []),
    ];
  }, [
    showOrgName,
    superUser,
    role,
    type,
    isEnterprise,
    premiumUsers,
    isTouch,
    isMobile,
    originalLicenses,
    authedUserId,
  ]);

  const handleRowClick = (row: MRT_Row<UserType>) => {
    const userId = row.original.id;
    if (userId) navigate(`/users/${userId}`);
  };

  const [tableState] = useStatefullTableAtom<UserType>('org_users_table', {
    ...(editPermissions && {
      columnVisibility: {
        mobile: true,
        is_verified: true,
        license: true,
        newsletter: true,
      },
      pagination: {
        pageSize: 10,
        pageIndex: 0,
      },
    }),
  });

  const handleSaveEditedUsers = () => {
    setIsEditing(true);
    Object.entries(editedUsers).forEach(([userId, user]) => {
      permissionMutation.mutate(
        {user_id: userId, permissions: {org_id, role: user.org_role}},
        {
          onSuccess: () => {
            setEditedUsers({});
            setIsEditing(false);
            toast.success('Brugerrettigheder er blevet opdateret');
          },
          onError: () => {
            setIsEditing(false);
            toast.error('Du har ikke lov til at redigere: ' + user.email);
          },
        }
      );
    });

    if ((!licenseInfo || needAdditionalLicensing) && differenceInPremiumUsers > 0) {
      setOpenAdditionalLicensing(true);
    } else {
      if (differenceInPremiumUsers == 0) {
        // setIsEditing(false);
        setIsEdited(false);
        return;
      }
      const newPremiumLicenseChanges = premiumUsers
        .filter(
          (user_id) => !original_premium_users?.some((license) => license.user_id === user_id)
        )
        .map((user_id) => ({user_id, license_id: null}));
      const newGratisLicenseChanges = original_premium_users
        ? original_premium_users
            .filter((license) => !premiumUsers.includes(license.user_id))
            .map((license) => ({user_id: license.user_id, license_id: 'free'}))
        : [];

      const switchLicensingStructure: SwitchLicensingType = {
        licenseChanges: [...newGratisLicenseChanges, ...newPremiumLicenseChanges],
        org_id: org_id,
      };
      setIsEditing(true);
      switchLicensingMutation.mutate(switchLicensingStructure, {
        onSuccess: () => {
          setIsEditing(false);
        },
        onError: () => {
          setIsEditing(false);
        },
      });
    }
  };

  const options: Partial<MRT_TableOptions<UserType>> = {
    getRowId: (row) => row.id,
    editDisplayMode: 'table', // ('modal', 'row', 'cell', and 'custom' are also
    enableEditing: true,
    renderTopToolbar: ({table}) => {
      if (!editPermissions) return null;

      if (isMobile)
        return (
          <Box
            sx={{
              display: ' flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              minHeight: '100px',
              mx: 0.5,
            }}
          >
            <Box sx={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
              <Box sx={{display: 'flex'}}>
                {(role === 'ADMIN' || superUser) && showInvite && (
                  <Button
                    sx={{height: '40px', mr: 0.5}}
                    onClick={() => setOpenInvite(true)}
                    bttype="primary"
                    startIcon={<SupervisedUserCircleIcon />}
                  >
                    {isMobile ? 'Inviter' : 'Inviter Bruger'}
                  </Button>
                )}
                {superUser && (
                  <Button
                    sx={{height: '40px', mr: 0.5}}
                    onClick={() => setOpenAddUser(true)}
                    bttype="primary"
                    startIcon={<PersonAddIcon />}
                  >
                    {isMobile ? 'Opret' : 'Opret Bruger'}
                  </Button>
                )}
              </Box>

              <Button
                sx={{height: '40px'}}
                color="success"
                bttype="primary"
                onClick={handleSaveEditedUsers}
                disabled={!isEdited}
                startIcon={<Save />}
              >
                {isEditing ? (
                  <LinearLoading width="x-small" height="small" color="primary" />
                ) : (
                  'Gem'
                )}
              </Button>
            </Box>
            <Box sx={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
              <MRT_GlobalFilterTextField table={table} sx={{width: '200px'}} />
              <Box
                sx={{
                  width: '90px',
                  display: 'flex',
                  alignItems: 'start',
                  justifyContent: 'space-evenly',
                }}
              >
                <MRT_ToggleFiltersButton table={table} />
                <MRT_ShowHideColumnsButton table={table} />
              </Box>
            </Box>
          </Box>
        );

      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 1,
            margin: 1,
          }}
        >
          <Box sx={{display: 'flex'}}>
            {(role === 'ADMIN' || superUser) && showInvite && (
              <>
                <Button
                  sx={{height: '40px', mr: 1, minWidth: '148px'}}
                  onClick={() => setOpenInvite(true)}
                  bttype="primary"
                  startIcon={<SupervisedUserCircleIcon />}
                >
                  Inviter bruger
                </Button>
              </>
            )}
            {superUser && (
              <Button
                sx={{height: '40px', mr: 1, minWidth: '144px'}}
                onClick={() => setOpenAddUser(true)}
                bttype="primary"
                startIcon={<PersonAddIcon />}
              >
                Opret bruger
              </Button>
            )}

            <Button
              color="success"
              bttype="primary"
              onClick={handleSaveEditedUsers}
              disabled={!isEdited}
              startIcon={<Save />}
            >
              {isEditing ? <LinearLoading width="small" height="small" color="primary" /> : 'Gem'}
            </Button>
          </Box>

          <Box sx={{display: 'flex', gap: 0.5}}>
            <MRT_GlobalFilterTextField table={table} />
            <MRT_ToggleFiltersButton table={table} />
            <MRT_ShowHideColumnsButton table={table} />
          </Box>
        </Box>
      );
    },
    muiTableBodyRowProps: ({row}) => ({
      onClick: () => {
        handleRowClick(row);
      },
      sx: {
        cursor: 'pointer', //you might want to change the cursor too when adding an onClick
      },
    }),

    enableBottomToolbar: type === TableTypes.TABLE ? true : false,
  };

  const table = useTable(columns, data, options, tableState, type);

  if (licenseInfo === undefined && editPermissions) return null;

  return (
    <>
      <Container
        sx={{
          my: 0,
          mx: 0,
          paddingRight: '0em !important',
          paddingLeft: '0em !important',
          width: '100%',
          maxWidth: '100% !important',
          height: '100%',
        }}
      >
        <InviteUserDialog open={openInvite} setOpen={setOpenInvite} />
        <GeneralAddUserDialog open={openAddUser} setOpen={setOpenAddUser} org_id={orgId} />
        <ConfirmAdditionalLicensingDialog
          open={openAdditionalLicensing}
          setOpen={setOpenAdditionalLicensing}
          newPremiumUsers={differenceInPremiumUsers - (licenseInfo?.available_licenses ?? 0)}
          licenseChanges={{
            licenseChanges: premiumUsers
              .filter(
                (user_id) => !original_premium_users?.some((license) => license.user_id === user_id)
              )
              .map((user_id) => ({user_id, license_id: null})),
            org_id: org_id,
          }}
          showPageString={contentTo}
          setIsEditing={setIsEditing}
        />

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: tableHeight,
            minHeight: '420px',
          }}
        >
          <MaterialReactTable table={table} />
        </Box>
      </Container>
    </>
  );
};

export default UsersTable;
