import {zodResolver} from '@hookform/resolvers/zod';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import {useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useLocation} from 'react-router-dom';

import Button from '~/components/Button';
import FormInput from '~/components/FormInput';
import FormInputCheckbox from '~/components/FormInputCheckbox';
import LinearLoading from '~/components/LineLoader';
import OrganizationDropdown from '~/components/OrganizationDropdown';
import {useOrgs} from '~/hooks/queries/organizations';
import {useOrgRole} from '~/hooks/queries/permissions';
import {UserType, useEditUser, EditUserSchema, UserEditType} from '~/hooks/queries/users';
import {authStore} from '~/state/store';

type EditUserDialogProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  user: UserType;
};

const EditUserDialog = ({open, setOpen, user}: EditUserDialogProps) => {
  const editRights = useOrgRole(user?.organization_id);
  const putUser = useEditUser();
  const viewedUserId = useLocation().pathname.split('/')[2];
  const {user_id: ownUserId} = authStore();
  const user_id = viewedUserId || ownUserId || '';
  const formMethods = useForm<UserEditType>({
    resolver: zodResolver(EditUserSchema),
    defaultValues: {
      ...user,
      organization_id: user?.organization_id,
    },
  });
  const {data: organisationsData} = useOrgs();
  const [isEditing, setIsEditing] = useState(false);
  const {control} = formMethods;

  const handleEdit = async (values: UserEditType) => {
    setIsEditing(true);

    const userData = {
      ...values,
      user_id: user_id,
    };

    putUser.mutate(userData, {
      onSuccess: () => {
        setOpen(false);
        setIsEditing(false);
      },
      onError: () => {
        setOpen(false);
        setIsEditing(false);
      },
    });
  };

  if (!user) {
    return null;
  }
  return (
    <Dialog
      open={open}
      onClose={() => {
        formMethods.reset();
        setOpen(false);
      }}
      scroll="paper"
      PaperProps={{
        sx: {
          borderRadius: 4,
          padding: 1,
          paddingBottom: 1,
          boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
        },
      }}
    >
      <DialogTitle>Rediger bruger</DialogTitle>
      <DialogContent>
        <DialogContentText mb={2}>Nedenstående felter kan redigeres</DialogContentText>
        <FormProvider {...formMethods}>
          <FormInput name="first_name" label="Fornavn" fullWidth sx={{mb: 2}} />
          <FormInput
            name="last_name"
            label="Efternavn"
            placeholder="Efternavn"
            fullWidth
            sx={{mb: 2}}
          />
          {/* <FormInput name="email" label="E-mail" required fullWidth sx={{mb: 2}} /> */}
          {/* <FormControl sx={{mb: 2}} fullWidth>
            <InputLabel id="role-label">Rettighed</InputLabel>
            <Select
              id="org_role"
              labelId="role-label"
              label="Rettighed"
              defaultValue={user?.org_role}
              {...formMethods.register('org_role')}
              disabled={editRights == 'USER'}
            >
              <MenuItem value={Role.USER}>Bruger</MenuItem>
              <MenuItem value={Role.ADMIN}>Administrator</MenuItem>
              {editRights == 'SUPERUSER' && <MenuItem value={Role.SUPERUSER}>Superbruger</MenuItem>}
            </Select>
          </FormControl> */}
          {editRights && organisationsData && (
            <OrganizationDropdown
              control={control}
              organisations={organisationsData}
              // originalOrg={selectedOrg}
              // setViewedOrg={setSelectedOrg}
              // setValue={setValue}
            />
          )}
          <FormInput
            name="mobile"
            label="Mobil nr."
            placeholder="Telefonnummer"
            type="tel"
            fullWidth
            sx={{mb: 2}}
          />
          {/* <FormInput name="email" label="Email" fullWidth sx={{mb: 2}} disabled /> */}
          {/* <FormControl sx={{mb: 2}} fullWidth>
            <InputLabel id="license-label">Licens</InputLabel>
            <Select
              id="license"
              labelId="license-label"
              label="Licens"
              defaultValue={user?.license || License.FREE}
              {...formMethods.register('license')}
              disabled={!superUser}
            >
              <MenuItem value={License.FREE}>Gratis</MenuItem>
              <MenuItem value={License.PREMIUM}>Premium</MenuItem>
            </Select>
          </FormControl> */}
          {/* <FormInputCheckbox name="is_verified" label="Verificeret" /> */}
          <FormInputCheckbox name="newsletter" label="Tilmeldt nyhedsbrev" />
        </FormProvider>
      </DialogContent>
      <DialogActions sx={{paddingRight: 3}}>
        <Button
          bttype="tertiary"
          onClick={() => {
            formMethods.reset();
            setOpen(false);
          }}
        >
          Annuller
        </Button>
        <Box sx={{display: 'flex', width: 65, justifyContent: 'center'}}>
          {!isEditing ? (
            <Button bttype="primary" onClick={formMethods.handleSubmit(handleEdit)}>
              Gem
            </Button>
          ) : (
            <LinearLoading width="large" height="medium" color="primary" />
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default EditUserDialog;
