import {zodResolver} from '@hookform/resolvers/zod';
import {
  DialogActions,
  DialogContent,
  Dialog,
  DialogContentText,
  DialogTitle,
  Box,
} from '@mui/material';
import {AxiosError} from 'axios';
import {Dispatch, SetStateAction, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';

import Button from '~/components/Button';
import FormInput from '~/components/FormInput';
import LinearLoading from '~/components/LineLoader';
import {verifyCvr} from '~/api/functions';
import {
  OrganizationCreateType,
  AddOrganizationSchema,
  useCreateOrganization,
} from '~/hooks/queries/organizations';

type AddOrganizationDialogProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

const AddOrganizationDialog = ({open, setOpen}: AddOrganizationDialogProps) => {
  const postOrg = useCreateOrganization();
  const formMethods = useForm<OrganizationCreateType>({
    resolver: zodResolver(AddOrganizationSchema),
  });
  const {handleSubmit, trigger, setError, getValues, setValue} = formMethods;
  const [cvrLoading, setCvrLoading] = useState(false);
  const [isAdding, setIsAdding] = useState(false);

  const handleCVRLookup = async (cvr: string) => {
    setCvrLoading(true);
    const isValid = await trigger('cvr');

    if (!isValid) {
      setCvrLoading(false);
      return;
    }

    verifyCvr(cvr).then((result) => {
      if (result == null) {
        setError('cvr', {type: 'custom', message: 'Denne virksomhed findes ikke i Virk.'});
        setCvrLoading(false);

        return;
      }
      if ('error' in result && result.error == 'exists') {
        setError('cvr', {type: 'custom', message: 'Denne virksomhed findes allerede i Calypso.'});
        setCvrLoading(false);

        return;
      } else {
        if (!('error' in result)) {
          setValue('name', result.name);
          setValue('address', result.address);
          setValue('zip', String(result.zip));
          setValue('city', result.city);
          setValue('country', result.country);
          setCvrLoading(false);
        }
      }
    });
  };

  const handleCreate = async (values: OrganizationCreateType) => {
    // const result = postOrg.mutateAsync(values);
    setIsAdding(true);
    postOrg.mutate(values, {
      onSuccess: () => {
        setOpen(false);
        setIsAdding(false);
      },
      onError: (error) => {
        setIsAdding(false);
        const axiosError = error as AxiosError;
        if (axiosError.status == 400) {
          setError('root', {
            type: 'manual',
            message: 'Der skete en fejl under oprettelsen af organisationen. Prøv igen.',
          });
        } else if (axiosError.status == 404) {
          setError('cvr', {
            type: 'manual',
            message: 'Dette CVR findes ikke på virk.dk',
          });
        } else if (axiosError.status == 406) {
          setError('cvr', {
            type: 'manual',
            message: 'Organisationen er allerede oprettet i Calypso',
          });
        }
      },
    });
  };

  const handleClose = () => {
    setOpen(false);
    formMethods.reset();
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            borderRadius: 4,
            padding: 1,
            paddingBottom: 0,
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          },
        }}
      >
        <DialogTitle>Opret en organisation</DialogTitle>
        <DialogContent sx={{paddingBottom: 0}}>
          <DialogContentText mb={2}>
            For at oprette en organisation bedes du udfylde nedenstående felter.
          </DialogContentText>
          <FormProvider {...formMethods}>
            <Box sx={{display: 'flex', alignItems: 'center', gap: 2, mb: 2}}>
              <FormInput
                name="cvr"
                label="CVR"
                required
                sx={{borderColor: 'lightgray'}}
                fullWidth
              />
              <Box
                sx={{
                  display: 'flex',
                  height: 40,
                  width: 145,
                  mr: 2,
                  mt: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {!cvrLoading ? (
                  <Button bttype="primary" onClick={() => handleCVRLookup(getValues('cvr'))}>
                    Hent data
                  </Button>
                ) : (
                  <LinearLoading width="medium" height="medium" color="primary" />
                )}
              </Box>
            </Box>
            <FormInput
              name="name"
              label="Organisationens navn"
              required
              fullWidth
              sx={{
                mb: 2,
              }}
            />
            <FormInput
              name="address"
              label="Addresse"
              required
              fullWidth
              sx={{
                mb: 2,
              }}
            />
            <FormInput
              name="zip"
              label="Postnummer"
              required
              fullWidth
              sx={{
                mb: 2,
              }}
            />
            <FormInput
              name="city"
              label="By"
              required
              fullWidth
              sx={{
                mb: 2,
              }}
            />
            <FormInput
              name="country"
              label="Land"
              required
              fullWidth
              sx={{
                mb: 2,
              }}
            />
            <FormInput
              name="website"
              label="Hjemmeside"
              fullWidth
              sx={{
                mb: 2,
              }}
            />
          </FormProvider>
        </DialogContent>
        <DialogActions sx={{paddingRight: 3}}>
          <Button bttype="tertiary" onClick={handleClose}>
            Annuller
          </Button>

          <Box sx={{display: 'flex', width: 159, justifyContent: 'center'}}>
            {!isAdding ? (
              <Button bttype="primary" onClick={handleSubmit(handleCreate)}>
                Opret organisation
              </Button>
            ) : (
              <LinearLoading width="large" height="medium" color="primary" />
            )}
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddOrganizationDialog;
