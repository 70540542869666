import {zodResolver} from '@hookform/resolvers/zod';
import {Typography} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {AxiosError} from 'axios';
import React from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {toast} from 'react-toastify';
import {z} from 'zod';

import Button from '~/components/Button';
import FormInput from '~/components/FormInput';
import {useSelfChangePassword} from '~/hooks/queries/useLogin';

import {passwordField} from '../login/Register';

interface SelfChangePasswordDialogProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SelfChangePasswordSchema = z.object({
  password: passwordField,
});

type FormValues = z.infer<typeof SelfChangePasswordSchema>;

const SelfChangePasswordDialog = ({open, setOpen}: SelfChangePasswordDialogProps) => {
  const mutation = useSelfChangePassword();

  const formMethods = useForm<FormValues>({
    resolver: zodResolver(SelfChangePasswordSchema),
    defaultValues: {
      password: '',
    },
  });

  const {handleSubmit} = formMethods;

  const handlePasswordChange = async (values: FormValues) => {
    mutation.mutate(
      {password: values.password},
      {
        onSuccess: () => {
          toast.success('Din adgangskode er blevet ændret!');
          setOpen(false);
        },
        onError: (error: Error) => {
          const newError = error as AxiosError;
          toast.error((newError?.response?.data as {detail?: string}).detail || '');
        },
      }
    );
  };

  const handleClose = () => {
    setOpen(false);
    formMethods.reset();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
          borderRadius: 4,
          padding: 1,
          paddingBottom: 0,
          boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
        },
      }}
    >
      <FormProvider {...formMethods}>
        <DialogTitle id="alert-dialog-title">
          <Typography variant="h5">Ændre adgangskode</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography>Ændre din adgangskode ved at indtaste den i nedenstående felt</Typography>
          <FormInput
            name="password"
            label="Kodeord"
            type={'password'}
            fullWidth
            sx={{
              mb: 2,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} bttype="tertiary">
            Annuller
          </Button>
          <Button onClick={handleSubmit(handlePasswordChange)} bttype="primary">
            Bekræft
          </Button>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
};

export default SelfChangePasswordDialog;
