import {useMutation, useQuery} from '@tanstack/react-query';

import {apiClient} from '~/api/apiClient';

export type Project = {
  customer_name: string;
  permission: string;
  project_info: string;
  project_no: string;
};

export type Unit = {
  calypso_id: string;
  projectno: string;
  terminal_id: string;
  startdate: string;
};

export type Permissions = {
  org_roles: {
    org_id: string;
    role: string;
  }[];
};

export type PermissionsMutation = {
  user_id: string;
  permissions: {
    org_id: string;
    role: string;
  };
};

const usePermissions = () =>
  useQuery({
    queryKey: ['permissions'],
    queryFn: async () => {
      const {data} = await apiClient.get<Permissions>(`/admin/my-permissions`);
      return data;
    },
    refetchOnWindowFocus: false,
  });

const usePermissionMutation = () => {
  return useMutation({
    mutationFn: async (payload: PermissionsMutation) => {
      const {data} = await apiClient.post(
        `/admin/users/${payload.user_id}/permissions`,
        payload.permissions
      );
      return data;
    },
  });
};

const useOrgRole = (org_id: string | undefined) => {
  const {data} = usePermissions();
  const foundSuperUser = data?.org_roles?.find((role) => role.role == 'SUPERUSER');
  if (foundSuperUser) {
    return 'SUPERUSER';
  } else {
    const orgRole = data?.org_roles.find((role) => role.org_id === org_id);
    return orgRole?.role;
  }
};
const useProjects = (user_id: string | null) =>
  useQuery({
    queryKey: ['getProjects', user_id],
    queryFn: async () => {
      const {data} = await apiClient.get<Project[]>(`/admin/projects/${user_id}`);
      return data;
    },
    enabled: !!user_id,
    refetchOnWindowFocus: false,
  });

const useUnits = (user_id: string | null) =>
  useQuery({
    queryKey: ['getUnits', user_id],
    queryFn: async () => {
      const {data} = await apiClient.get<Unit[]>(`/admin/units/${user_id}`);
      return data;
    },
    enabled: !!user_id,
    refetchOnWindowFocus: false,
  });
const useOrgProjects = (org_id: string) =>
  useQuery({
    queryKey: ['getOrgProjects', org_id],
    queryFn: async () => {
      const {data} = await apiClient.get<Project[]>(`/admin/org_projects/${org_id}`);
      return data;
    },
    refetchOnWindowFocus: false,
  });

const useOrgUnits = (org_id: string) =>
  useQuery({
    queryKey: ['getOrgUnits', org_id],
    queryFn: async () => {
      const {data} = await apiClient.get<Unit[]>(`/admin/org_units/${org_id}`);
      return data;
    },
    refetchOnWindowFocus: false,
  });

export {
  usePermissions,
  useOrgRole,
  useProjects,
  useUnits,
  useOrgProjects,
  useOrgUnits,
  usePermissionMutation,
};
