import {zodResolver} from '@hookform/resolvers/zod';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import {Dispatch, SetStateAction, useState} from 'react';
import {FieldValues, FormProvider, useForm} from 'react-hook-form';
import {useParams} from 'react-router-dom';

import Button from '~/components/Button';
import FormInput from '~/components/FormInput';
import LinearLoading from '~/components/LineLoader';
import {InviteUserSchema, useCreateInvite, InviteUserType} from '~/hooks/queries/users';
import useBreakpoints from '~/hooks/useBreakpoints';
import {authStore, Role} from '~/state/store';

type InviteUserDialogProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

const InviteUserDialog = ({open, setOpen}: InviteUserDialogProps) => {
  const inviteUser = useCreateInvite();
  const {orgId} = useParams();
  const userOrgId = authStore((state) => state.org_id);
  const org_id = orgId || userOrgId || '';
  const [loading, setLoading] = useState(false);
  const breakpoints = useBreakpoints();
  const formMethods = useForm<InviteUserType>({
    resolver: zodResolver(InviteUserSchema),
    defaultValues: {
      email: '',
      role: Role.USER,
      org_id: org_id,
    },
  });

  const {handleSubmit} = formMethods;

  const handleInvite = async (values: FieldValues) => {
    setLoading(true);
    const userData: InviteUserType = {
      email: values.email,
      role: values.role,
      org_id: org_id,
    };

    inviteUser.mutate(userData, {
      onSuccess: () => {
        setOpen(false);
        setLoading(false);
      },
      onError: (error) => {
        console.error('Error inviting user:', error);
        setLoading(false);
      },
    });
  };

  const handleClose = () => {
    formMethods.reset();
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          borderRadius: 4,
          padding: 1,
          paddingBottom: 1,
          boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
        },
      }}
    >
      <DialogTitle>Tilføj bruger til organisation</DialogTitle>
      <DialogContent sx={{paddingBottom: 0}}>
        <DialogContentText mb={2}>
          For at oprette en bruger bedes du indtaste brugerens e-mail og vælge brugerens rettighed.
          Brugeren vil modtage en invitation til at oprette en konto. Når brugeren har oprettet en
          konto, vil de have adgang til organisationen.
        </DialogContentText>

        <FormProvider {...formMethods}>
          <FormInput
            name="email"
            label="E-mail"
            required
            fullWidth
            sx={{
              mb: 2,
            }}
          />
          <FormControl sx={{mb: 2}} required variant="outlined" fullWidth>
            <InputLabel variant="outlined" id="role">
              Rettighed
            </InputLabel>
            <Select
              variant="outlined"
              placeholder="Rettighed"
              id="role"
              labelId="role-label"
              label="Rettighed"
              defaultValue={Role.USER}
              {...formMethods.register('role')}
            >
              <MenuItem value={Role.USER}>Bruger</MenuItem>
              <MenuItem value={Role.ADMIN}>Administrator</MenuItem>
            </Select>
          </FormControl>
        </FormProvider>
      </DialogContent>
      <DialogActions sx={{marginX: 2}}>
        <Button bttype="tertiary" onClick={handleClose}>
          Annuller
        </Button>
        <Box sx={{display: 'flex', justifyContent: 'center'}}>
          {!loading ? (
            <Button bttype="primary" onClick={handleSubmit(handleInvite)}>
              {breakpoints.isMobile ? 'Inviter' : 'Send invitation'}
            </Button>
          ) : (
            <LinearLoading height="medium" width="large" color="primary" />
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default InviteUserDialog;
