import {Paper, Divider} from '@mui/material';

import {TableHeaderText, TableHeaderType} from '~/components/TableHeaders';
import {useProjects} from '~/hooks/queries/permissions';
import ProjectsTable from '~/pages/projects/AllProjectsTable';
import {authStore} from '~/state/store';

const Projects = () => {
  const {user_id} = authStore();
  const {data} = useProjects(user_id);

  return (
    <Paper elevation={4} sx={{justifyContent: 'center', margin: 2, padding: 0}}>
      <TableHeaderText tableType={TableHeaderType.Project} />
      <Divider
        sx={{
          width: '100%',
          borderBottomWidth: '2px',
          borderBottomColor: '#00786d',
          marginTop: '1em',
        }}
      />
      <ProjectsTable data={data} />
    </Paper>
  );
};

export default Projects;
