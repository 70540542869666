import {zodResolver} from '@hookform/resolvers/zod';
import {Box, Container} from '@mui/material';
import {useEffect, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useSearchParams} from 'react-router-dom';
import {toast} from 'react-toastify';

import LinearLoading from '~/components/LineLoader';
import {appBarHeight} from '~/consts';
import {
  EmailPreferencesSchema,
  EmailPreferencesType,
  useCheckExistingKlaviyoOrSystemUser,
} from '~/hooks/queries/users';

import EmailPreferencesForm from './EmailPreferencesForm';
import VerifiedCountdown from './VerifiedCountdown';

export default function VerifiedAndEmailPage() {
  const [searchParams] = useSearchParams();
  const redirectKey = searchParams.get('redirect');
  const email = searchParams.get('subscribeEmail');
  const {data: showForm, isLoading} = useCheckExistingKlaviyoOrSystemUser(email || '');
  const formMethods = useForm<EmailPreferencesType>({
    resolver: zodResolver(EmailPreferencesSchema),
    defaultValues: {
      email: email || '',
      software: false,
      vandloeb_overfladevand: false,
      grundvand: false,
      natur: false,
      produkter_vandloeb: false,
      produkter_grundvand: false,
      dambrug: false,
    },
  });
  const [page, setPage] = useState(2);
  useEffect(() => {
    if (email !== null && showForm === undefined) {
      return;
    }
    if (showForm) {
      setPage(0);
      toast.success('Du er nu verificeret og kan vælge dine præferencer');
    } else if (!showForm) {
      setPage(1);
    }
  }, [showForm, isLoading, email]);

  return (
    <FormProvider {...formMethods}>
      <Container sx={{marginTop: appBarHeight}} maxWidth="sm">
        {page === 0 && <EmailPreferencesForm setPage={setPage} email={email || ''} />}
        {page === 1 && <VerifiedCountdown redirectKey={redirectKey} />}
        {page === 2 && (
          <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <LinearLoading width="large" height="large" color="primary" />
          </Box>
        )}
      </Container>
    </FormProvider>
  );
}
