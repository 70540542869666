import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box,
  Tabs,
  Tab,
  Paper,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import {useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';

import {activateProjects, activateUnits} from '~/consts';
import {useOrg} from '~/hooks/queries/organizations';
import {useOrgProjects, useOrgUnits, Project, useOrgRole} from '~/hooks/queries/permissions';
import {useOrgUsers} from '~/hooks/queries/users';
import useBreakpoints from '~/hooks/useBreakpoints';
import {TableTypes} from '~/hooks/useTable';
import OrganizationDetails from '~/pages/organizations/orgDetailComponents/OrganizationDetails';
import OrgProjectsTable from '~/pages/projects/OrgProjectsTable';
import OnlyOrgUnitsTable from '~/pages/units/OnlyOrgUnitsTable';
import UsersTable from '~/pages/users/UsersTable';
import {authStore} from '~/state/store';

import LicencesTable from '../licences/LicencesTable';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{width: '100%'}}
    >
      {value === index && <Box sx={{p: 0}}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const OrganizationsPage = () => {
  // utility
  const {isMobile} = useBreakpoints();
  const {orgId} = useParams();
  const userOrgId = authStore((state) => state.org_id);
  const superUser = authStore((state) => state.superUser);
  const org_id = orgId || userOrgId || '';
  const role = useOrgRole(org_id ?? '');
  const editPermissions = role === 'ADMIN' || superUser;

  // fetches
  const {data: orgProjects} = useOrgProjects(org_id);
  const {data: orgUnits} = useOrgUnits(org_id);
  const {data: orgInfo} = useOrg(org_id);
  const {data: orgUsers} = useOrgUsers(org_id);

  const isEnterprise = orgUsers?.some((user) => user.license === 'ENTERPRISE');

  // modal state
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(isMobile ? false : true);

  const [chosenIndex, setChosenIndex] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newIndex: number) => {
    setChosenIndex(newIndex);
  };

  const organizationProjects = orgProjects
    ?.filter((project: Project) => project.permission === 'READ')
    .map((project: Project) => {
      if (project.project_info === '') {
        project.project_info = project.customer_name;
      }
      return project;
    });

  const usersTable = useMemo(() => {
    return <UsersTable data={orgUsers} type={TableTypes.SCROLLING} isEnterprise={isEnterprise} />;
  }, [orgUsers, isEnterprise]);

  const licensesTable = useMemo(() => {
    if (orgUsers === undefined) {
      return;
    }
    return <LicencesTable orgUsers={orgUsers} type={TableTypes.SCROLLING} />;
  }, [orgUsers]);

  return (
    <Paper elevation={4} sx={{width: '100%', margin: 0, mt: 0, minWidth: '200px'}}>
      <Box sx={{height: '100%', width: '100%', margin: 0, padding: 0}}>
        <Accordion
          elevation={0}
          defaultExpanded={isAccordionExpanded}
          onChange={() => setIsAccordionExpanded(!isAccordionExpanded)}
          sx={{
            borderBottomWidth: '0px',
            borderBottomColor: 'primary.main',
            borderBottomStyle: 'solid',
            marginBottom: '0px !important',
            width: '100%',
          }}
        >
          <AccordionSummary
            aria-controls="panel1-content"
            id="panel1-header"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              height: '75px',
              width: '100%',
              padding: 0,
              paddingTop: '16px',
              margin: 0,
              '& .Mui-expanded': {
                minHeight: 0,
              },
              '& .MuiAccordionSummary-content': {
                justifyContent: 'center',
                alignItems: 'center',
              },
              '& .MuiSvgIcon-root': {
                fontSize: '35px',
                color: 'green',
              },
            }}
          >
            <Box sx={{margin: 'auto'}}>
              <Typography
                variant={isMobile ? 'subtitle1' : 'h5'}
                sx={{
                  fontWeight: 900,
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  textAlign: 'center',
                }}
              >
                {orgInfo?.name}
              </Typography>
              {isAccordionExpanded ? (
                <ExpandLessIcon sx={{m: 'auto', width: '100%'}} />
              ) : (
                <ExpandMoreIcon sx={{m: 'auto', width: '100%'}} />
              )}
            </Box>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              padding: 0,
              marginBottom: 0,
            }}
          >
            <OrganizationDetails
              projects={organizationProjects}
              units={orgUnits}
              users={orgUsers}
              org_data={orgInfo}
            />
          </AccordionDetails>
        </Accordion>

        <Box
          sx={{
            borderBottom: 1,
            height: '80%',
            borderColor: 'divider',
            display: 'flex',
            maxWidth: '100% ',
            justifyContent: 'center',
          }}
        >
          <Tabs
            sx={{height: '40px', marginTop: '6px', alignItems: 'end', minHeight: '35px'}}
            value={chosenIndex}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab sx={{fontWeight: 'bold'}} label="Brugere" {...a11yProps(0)} />
            {editPermissions && !isEnterprise && (
              <Tab sx={{fontWeight: 'bold'}} label="Licens" {...a11yProps(1)} />
            )}
            {activateProjects && (
              <Tab sx={{fontWeight: 'bold'}} label="Projekter" {...a11yProps(2)} />
            )}
            {activateUnits && <Tab sx={{fontWeight: 'bold'}} label="Udstyr" {...a11yProps(3)} />}
          </Tabs>
        </Box>
        <CustomTabPanel value={chosenIndex} index={0}>
          {usersTable}
        </CustomTabPanel>
        <CustomTabPanel value={chosenIndex} index={1}>
          {orgUsers && editPermissions && licensesTable}
        </CustomTabPanel>
        <CustomTabPanel value={chosenIndex} index={2}>
          <OrgProjectsTable data={organizationProjects} />
        </CustomTabPanel>
        <CustomTabPanel value={chosenIndex} index={3}>
          <OnlyOrgUnitsTable unitsData={orgUnits} />
        </CustomTabPanel>
      </Box>
    </Paper>
  );
};

export default OrganizationsPage;
