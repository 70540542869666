import {TextField} from '@mui/material';
import type {TextFieldProps} from '@mui/material';
import moment from 'moment';
import {ReactNode} from 'react';
import {Controller, get, useFormContext, FieldValues, Path, RegisterOptions} from 'react-hook-form';

type FormInputProps<TFieldValues extends FieldValues = FieldValues> = {
  name: Path<TFieldValues>;
  warning?: (value: any) => boolean;
  children?: ReactNode;
  rules?: RegisterOptions<TFieldValues>;
  transform?: (value: any) => any;
} & Omit<TextFieldProps, 'name' | 'ref' | 'onChange' | 'error' | 'helperText'>;

const FormInput = ({name, warning, children, rules, transform, ...otherProps}: FormInputProps) => {
  const {
    control,
    formState: {errors},
  } = useFormContext();

  if (transform == undefined) {
    transform = (e) => e;
  }
  const trans_function = transform;

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({field: {value, onChange, onBlur, ref, name}}) => {
        if (otherProps.type === 'datetime-local' && value) {
          value = moment(value).format('YYYY-MM-DDTHH:mm');
        }

        return (
          <TextField
            name={name}
            value={value}
            onBlur={(event) => {
              onBlur();
              if (typeof otherProps.onBlur === 'function') {
                otherProps.onBlur(event);
              }
            }}
            ref={ref}
            // defaultValue={''}
            sx={{
              '& .MuiInputBase-input.Mui-disabled': {
                WebkitTextFillColor: '#000000',
              },
              '& .MuiInputLabel-root': {color: 'primary.main'}, //styles the label
              '& .MuiInputLabel-root.Mui-disabled': {color: 'rgba(0, 0, 0, 0.38)'}, //styles the label
              '& .MuiOutlinedInput-root': {
                '& > fieldset': {borderColor: 'primary.main'},
              },
              '.MuiFormHelperText-root': {color: warning && warning(value) ? 'orange' : 'red'},
            }}
            {...otherProps}
            className="swiper-no-swiping"
            variant="outlined"
            InputLabelProps={{shrink: true}}
            margin="dense"
            onChange={(e) => {
              if (otherProps.type === 'number' && e.target.value !== '') {
                onChange(trans_function(Number(e.target.value)));
              } else {
                onChange(trans_function(e));
              }
            }}
            error={!!get(errors, name)}
            helperText={
              get(errors, name) ? get(errors, name).message : '' || (warning && warning(value))
            }
          >
            {children}
          </TextField>
        );
      }}
    />
  );
};

export default FormInput;
