import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import {Dispatch, SetStateAction, useState} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';

import Button from '~/components/Button';
import LinearLoading from '~/components/LineLoader';
import {usePostLicensing} from '~/hooks/queries/licenses';
import {UserType} from '~/hooks/queries/users';
import useBreakpoints from '~/hooks/useBreakpoints';
import {authStore} from '~/state/store';

type AddLicenseDialogProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  userOptions: UserType[];
  showPageString: string;
};

const AddLicenseDialog = ({open, setOpen, userOptions, showPageString}: AddLicenseDialogProps) => {
  const {orgId} = useParams();
  const userOrgId = authStore((state) => state.org_id);
  const org_id = orgId || userOrgId || '';
  const postMutation = usePostLicensing();
  const navigate = useNavigate();
  const [isAdding, setIsAdding] = useState(false);
  const {isMobile} = useBreakpoints();
  const handleConfirm = () => {
    setIsAdding(true);
    postMutation.mutate(
      {
        org_id,
        count: 1,
        user_id: chosenUser ? chosenUser?.id : null,
      },
      {
        onSuccess: () => {
          setIsAdding(false);
          setOpen(false);
          setChosenUser(null);
        },
        onError: () => {
          setIsAdding(false);
          setOpen(false);
        },
      }
    );
  };

  const [chosenUser, setChosenUser] = useState<UserType | null>(userOptions[0]);
  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const selectedUser = userOptions.find((user) => user.id === event.target.value);
    if (selectedUser) {
      setChosenUser(selectedUser);
    } else {
      setChosenUser(null);
    }
  };

  const invalidInvoice = (
    <Dialog
      open={open}
      onClose={(reason) => {
        if (reason === 'backdropClick') {
          return;
        }
        setOpen(false);
      }}
      PaperProps={{
        sx: {
          borderRadius: 4,
          padding: 2,
          paddingBottom: 1,
          boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
        },
      }}
    >
      <DialogTitle>Mangler faktureringsoplysninger</DialogTitle>
      <DialogContent sx={{paddingBottom: 0}}>
        <DialogContentText>
          Der er ikke angivet betalingsinformation for denne organisation.<br></br> Gå til
          <Link to={`/organizations/${org_id}/edit`}> faktureringsoplysninger</Link> for at angive
          oplysninger.
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{marginX: 2}}>
        <Button bttype="tertiary" onClick={() => setOpen(false)}>
          Annuller
        </Button>
        <Box sx={{display: 'flex', width: isMobile ? 180 : 230, justifyContent: 'center'}}>
          {!isAdding ? (
            <Button bttype="primary" onClick={() => navigate(`/organizations/${org_id}/edit`)}>
              {isMobile ? 'Fakturingsoplysninger' : 'Gå til faktureringsoplysninger'}
            </Button>
          ) : (
            <LinearLoading width="medium" height="medium" color="primary" />
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );

  const validInvoice = (
    <Dialog
      open={open}
      onClose={(reason) => {
        if (reason === 'backdropClick') {
          return;
        }
        setOpen(false);
      }}
      PaperProps={{
        sx: {
          borderRadius: 4,
          padding: 1,
          paddingBottom: 1,
          boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
        },
      }}
    >
      <DialogTitle>Opret ny licens</DialogTitle>
      <DialogContent sx={{paddingBottom: 0}}>
        <DialogContentText mb={2}>
          En premium licens koster 2400 kr.- årligt. Du kan vælge at tildele licensen til en bruger
          med det samme eller blot oprette licensen og tildele den senere.
        </DialogContentText>
        <Typography sx={{mb: 1}}>Vælg bruger:</Typography>
        <Select
          fullWidth
          defaultValue={'ingen_bruger'}
          placeholder="Vælg bruger"
          value={chosenUser?.id || 'ingen_bruger'}
          onChange={handleSelectChange}
        >
          {userOptions?.map((user) => (
            <MenuItem key={user.id} value={user.id}>
              {user?.first_name} {user?.last_name} - ({user?.email})
            </MenuItem>
          ))}
          <MenuItem key={'ingen_bruger'} value={'ingen_bruger'}>
            Opret licens uden bruger
          </MenuItem>
        </Select>
      </DialogContent>
      <DialogActions sx={{marginX: 2}}>
        <Button bttype="tertiary" onClick={() => setOpen(false)}>
          Annuller
        </Button>
        <Box sx={{display: 'flex', width: 85, justifyContent: 'center'}}>
          {!isAdding ? (
            <Button bttype="primary" onClick={handleConfirm}>
              Bekræft
            </Button>
          ) : (
            <LinearLoading width="medium" height="medium" color="primary" />
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );

  if (!userOptions) {
    return null;
  }

  return showPageString === 'invalidInvoice' ? invalidInvoice : validInvoice;
};

export default AddLicenseDialog;
