import {zodResolver} from '@hookform/resolvers/zod';
import Container from '@mui/material/Container';
import React, {useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useSearchParams} from 'react-router-dom';
import * as z from 'zod';

import {appBarHeight} from '~/consts';
import {useSignup, checkEmailExists, checkEmailDomain} from '~/hooks/queries/useLogin';

import ConfirmDomainDialog from './ConfirmDomainDialog';
import CreateOrganisationDialog from './CreateOrganisationDialog';
import RegisterForm from './signupPages/RegisterForm';

export const passwordField = z
  .string({required_error: 'Kodeord er påkrævet'})
  .min(8, 'Kodeord skal være længere end 8 tegn')
  .max(24, 'Kodeord må ikke være længere end 24 tegn')
  .superRefine((value, ctx) => {
    const hasCapital = /[A-Z]/.test(value);
    const hasSmall = /[a-z]/.test(value);
    const hasNumber = /\d/.test(value);
    let message: string | null = null;
    const specialReg = /[!"#¤%&/()=?@£$€{}|]/g;
    const hasUnallowedSpecialChar = specialReg.test(value);
    // const hasNonUnicodeChar = /[^\x00-\x7F]/.test(value);
    if (!(hasCapital && hasSmall && hasNumber)) {
      message = 'Kodeord skal indeholde mindst et stort og et lille bogstav og et tal';
    }
    if (hasUnallowedSpecialChar) {
      message = `Kodeord må ikke indeholde ${value.match(specialReg)}`;
    }
    if (message) {
      ctx.addIssue({message, code: z.ZodIssueCode.custom});
    }
  });

const RegisterSchema = z.object({
  firstName: z
    .string({required_error: 'Fornavn er påkrævet'})
    .min(2, 'Fornavn skal være længere end 2 tegn')
    .max(50, 'Fornavn må ikke være længere end 50 tegn'),
  lastName: z
    .string({required_error: 'Efternavn er påkrævet'})
    .min(2, 'Efternavn skal være længere end 2 tegn')
    .max(50, 'Efternavn må ikke være længere end 50 tegn'),
  password: passwordField,
  // .refine((value) => {
  //   const hasCapital = /[A-Z]/.test(value);
  //   const hasSmall = /[a-z]/.test(value);
  //   const hasNumber = /\d/.test(value);
  //   return hasCapital && hasSmall && hasNumber;
  // }, 'Kodeord skal indeholde mindst en stor og en lille bogstav og et tal'),
  email: z
    .string({required_error: 'Email er påkrævet'})
    .min(1, 'Email er påkrævet')
    .email('Email er ugyldig'),
  join_domain: z.boolean().optional().default(false),
  cvr: z
    .string()
    .min(8, 'CVR nummeret skal være 8 cifre langt')
    .max(8, 'CVR nummeret skal være 8 cifre langt')
    .nullish(),
  checkedTerms: z.literal(true, {
    errorMap: () => ({message: 'Du skal acceptere betingelserne for at oprette en konto'}),
  }),
  newsletter: z.boolean().optional().default(false),
});

export type RegisterFormValues = z.infer<typeof RegisterSchema>;

export default function Register() {
  const [domainOrg, setDomainOrg] = useState<string>('');
  const [showForm, setShowForm] = useState<'register' | 'organisation' | 'domain'>('register');
  const [searchParams] = useSearchParams();

  const formMethods = useForm<RegisterFormValues>({
    resolver: zodResolver(RegisterSchema),
    defaultValues: {
      email: searchParams.get('email') || '',
    },
  });

  const {getValues} = formMethods;
  const signupUser = useSignup();
  const [userCreated, setUserCreated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [mutationError, setMutationError] = useState<string | null>(null);
  const createUser = (values: RegisterFormValues) => {
    const key = encodeURIComponent(searchParams.get('redirect') || '');

    const payload = {
      email: values.email,
      first_name: values.firstName,
      last_name: values.lastName,
      join_domain: values.join_domain,
      cvr: values.cvr,
      password: values.password,
      newsletter: values.newsletter,
      redirectKey: key ? `?redirect=${key}` : '',
    };

    signupUser.mutate(payload, {
      onSuccess: () => {
        setUserCreated(true);
      },
      onError: (error) => {
        const errorMessage = (error as any)?.response?.data?.detail;
        setMutationError(errorMessage);
      },
    });
  };

  const handleCreate = async () => {
    // trigger form validation
    setIsLoading(true);
    const email = getValues().email;
    const emailExists = await checkEmailExists(email);

    if (emailExists) {
      formMethods.setError('email', {
        type: 'manual',
        message: 'Emailen er allerede i brug. Brug en anden email.',
      });
      setIsLoading(false);
      return;
    }

    const org_name = await checkEmailDomain(email);

    if (org_name) {
      setDomainOrg(org_name);
      setShowForm('domain');
    } else {
      setShowForm('organisation');
    }
    setIsLoading(false);
  };

  return (
    <FormProvider {...formMethods}>
      <Container sx={{marginTop: appBarHeight}} maxWidth="sm">
        {showForm == 'register' && (
          <RegisterForm isLoading={isLoading} onSubmitHandler={handleCreate} />
        )}
        {showForm == 'organisation' && (
          <CreateOrganisationDialog
            backToRegister={() => setShowForm('register')}
            createUser={formMethods.handleSubmit(createUser)}
            userCreated={userCreated}
            isMutating={signupUser.isPending}
            mutationError={mutationError}
          />
        )}

        {showForm == 'domain' && (
          <ConfirmDomainDialog
            domainOrg={domainOrg}
            backToRegister={() => setShowForm('register')}
            createUser={formMethods.handleSubmit(createUser)}
            userCreated={userCreated}
            isMutating={signupUser.isPending}
            mutationError={mutationError}
          />
        )}
      </Container>
    </FormProvider>
  );
}
