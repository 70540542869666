import {Typography, Box} from '@mui/material';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import React, {useEffect, useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {toast} from 'react-toastify';

import {loginAPI} from '~/api/functions';
import Button from '~/components/Button';
import LinearLoading from '~/components/LineLoader';
import {checkVerificationStatus} from '~/hooks/queries/useLogin';
import {authStore} from '~/state/store';

import ChangePasswordDialog from './ChangePasswordDialog';
import ForgotPasswordDialog from './ForgotPasswordDialog';

interface LoginProps {
  showResetPassword?: boolean;
}

export default function Login({showResetPassword = false}: LoginProps) {
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState('');
  const [forgetOpen, setForgetOpen] = useState(false);
  const [changeOpen, setChangeOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const [verificationKey, setVerificationKey] = useState<undefined | string>();
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  useEffect(() => {
    const key = searchParams.get('verification_key');
    if (searchParams.get('verification_key')) {
      setVerificationKey(key || '');
    }
  }, [searchParams]);

  const checkFetchRef = React.useRef(null);

  const navigate = useNavigate();

  const [setAuthenticated, setLoginExpired, setAuthorization] = authStore((state) => [
    state.setAuthenticated,
    state.setLoginExpired,
    state.setAuthorization,
  ]);

  const handleSubmit = () => {
    setIsLoggingIn(true);
    loginAPI(userName.toLowerCase().trim(), password)
      .then((response) => {
        setLoginError('');
        setAuthorization(response.data);
        setAuthenticated(true);
        setLoginExpired(false);
        setIsLoggingIn(false);
        navigate('/');
      })
      .catch((error) => {
        setLoginError(error.response.data.detail);
        setIsLoggingIn(false);
      });
  };

  const handleClickOpen = () => {
    setForgetOpen(true);
  };

  useEffect(() => {
    if (verificationKey) {
      checkVerificationStatus(verificationKey).then((response) => {
        if (response) {
          toast.info(response);
        } else {
          setChangeOpen(showResetPassword);
        }
      });
    }
  }, [verificationKey, showResetPassword]);

  return (
    <>
      <ForgotPasswordDialog forgetOpen={forgetOpen} setForgetOpen={setForgetOpen} />
      <ChangePasswordDialog changeOpen={changeOpen} setChangeOpen={setChangeOpen} />
      <Box
        sx={{
          borderRadius: 4,
          padding: '32px',
          maxWidth: 'sm',
          boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
        }}
      >
        <Box
          ref={checkFetchRef}
          sx={{
            textAlign: 'center',
            alignSelf: 'center',
          }}
        >
          <Typography mb={2} variant="h4">
            Log ind
          </Typography>
        </Box>

        <Container fixed maxWidth="sm">
          <Typography
            mb={2}
            style={{
              textAlign: 'center',
              alignSelf: 'center',
            }}
          >
            Log ind for at administrere din bruger, organisation og rettigheder
          </Typography>
          <Box
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
            noValidate
          >
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              onChange={(e) => setUserName(e.target.value)}
              error={!!loginError}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(e) => setPassword(e.target.value)}
              error={!!loginError}
              helperText={!!loginError && loginError}
            />
            <Box
              mt={1}
              mb={1}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                px: 2,
              }}
            >
              <Button
                bttype="link"
                onClick={() => navigate('/register')}
                sx={{
                  m: 0,
                  p: 0,
                }}
              >
                Opret konto
              </Button>
              <Button
                bttype="link"
                onClick={handleClickOpen}
                sx={{
                  m: 0,
                  p: 0,
                }}
              >
                Glemt kodeord?
              </Button>
            </Box>
            <Box
              sx={{
                width: '100%',
                height: '40px',
                mt: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {!isLoggingIn ? (
                <Button
                  type="submit"
                  fullWidth
                  bttype="primary"
                  disabled={userName === '' || password === ''}
                >
                  Log på
                </Button>
              ) : (
                <Box>
                  <LinearLoading width="large" height="large" color="primary" />
                </Box>
              )}
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
}
