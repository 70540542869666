import {Paper, Divider, Typography, Box} from '@mui/material';

import LinearLoading from '~/components/LineLoader';
import {useOrgs} from '~/hooks/queries/organizations';
import useBreakpoints from '~/hooks/useBreakpoints';
import {TableTypes} from '~/hooks/useTable';
import OrganizationsTable from '~/pages/organizations/OrganizationsTable';

const Organizations = () => {
  const {data, isLoading} = useOrgs();
  const {isMobile} = useBreakpoints();

  if (data === undefined) {
    return <LinearLoading width="large" height="large" />;
  }

  return (
    <Paper elevation={4} sx={{justifyContent: 'center', margin: 0, padding: 0}}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingX: isMobile ? 0 : 2,
          paddingY: isMobile ? 1 : 2,
          paddingBottom: isMobile ? 1 : 0,
        }}
      >
        <Typography variant="h5" fontWeight={900}>
          Organisationer
        </Typography>
      </Box>{' '}
      <Divider
        sx={{
          width: '100%',
          borderBottomWidth: '2px',
          borderBottomColor: '#00786d',
          marginTop: isMobile ? 0 : '1em',
        }}
      />
      <OrganizationsTable data={data} isLoading={isLoading} type={TableTypes.TABLE} />
    </Paper>
  );
};

export default Organizations;
