import {Edit} from '@mui/icons-material';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import DomainIcon from '@mui/icons-material/Domain';
import GroupsIcon from '@mui/icons-material/Groups';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {Paper, Box, Typography, Link} from '@mui/material';
import React from 'react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import {useNavigate, useParams} from 'react-router-dom';
import {Navigation, Autoplay} from 'swiper/modules';
import {Swiper, SwiperSlide} from 'swiper/react';

import Button from '~/components/Button';
import {OrgDetails} from '~/hooks/queries/organizations';
import {useOrgRole} from '~/hooks/queries/permissions';
import type {Project, Unit} from '~/hooks/queries/permissions';
import {UserType} from '~/hooks/queries/users';
import useBreakpoints from '~/hooks/useBreakpoints';
import {authStore} from '~/state/store';

export type OrganizationDetailsProps = {
  org_data: OrgDetails | undefined;
  projects: Project[] | undefined;
  units: Unit[] | undefined;
  users: UserType[] | undefined;
};
const slideStyle = {
  width: '100%',
  minWidth: '245px',
  overflow: 'hidden',
};

const InformationCarousel = ({org_data, users}: OrganizationDetailsProps) => {
  const {isMobile, isDesktop} = useBreakpoints();

  const numberOfSlides = isMobile ? 1 : isDesktop ? 3 : 2;
  const {superUser, org_id} = authStore();
  const params = useParams();
  const viewed_org = (params.orgId || org_id) ?? '';
  const role = useOrgRole(org_id ?? '');
  const editPermissions = role === 'ADMIN' || superUser;
  const navigate = useNavigate();
  const isEnterprise = users?.some((user) => user.license === 'ENTERPRISE');

  return (
    <>
      <KeyboardArrowLeftIcon
        className="swiper-button-prev"
        style={{
          color: 'green',
          cursor: 'pointer',
          width: '40px',
          position: 'relative',
          marginBottom: 'auto',
          marginRight: isMobile ? '0px' : '16px',
        }}
      ></KeyboardArrowLeftIcon>
      <Swiper
        modules={[Navigation, Autoplay]}
        spaceBetween={numberOfSlides === 1 ? 0 : numberOfSlides === 2 ? 10 : 25}
        autoplay={{delay: 15000}}
        slidesPerView={numberOfSlides}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          paddingLeft: 8,
          paddingRight: 8,
        }}
      >
        <SwiperSlide style={slideStyle}>
          <Paper
            className="swiper-no-swiping"
            sx={{
              margin: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: 1,
              height: '115px',
              backgroundColor: 'whitesmoke',
            }}
            elevation={3}
          >
            <GroupsIcon sx={{fontSize: 50, color: 'black'}} />
            <Typography sx={{fontWeight: 600, fontSize: 16, textAlign: 'center'}}>
              Antal brugere:
            </Typography>
            <Typography sx={{fontWeight: 600, fontSize: 16}}>{users?.length}</Typography>
          </Paper>
        </SwiperSlide>
        <SwiperSlide style={slideStyle}>
          <Paper
            className="swiper-no-swiping"
            sx={{
              margin: 1,
              display: 'flex',
              padding: 1,
              height: '115px',
              backgroundColor: 'whitesmoke',
            }}
            elevation={3}
          >
            <Box sx={{display: 'flex', width: '100%', height: '100%'}}>
              <Box
                sx={{
                  backgroundColor: 'whitesmoke',
                  width: 3 / 5,
                  height: '100%',
                  overflowY: 'scroll',
                  padding: 0,
                  scrollbarWidth: 'thin',
                  scrollbarColor: 'rgb(0,120,109, 0.2) transparent',
                  '&:hover': {
                    scrollbarColor: 'rgb(0,120,109, 0.5) transparent',
                  },
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: 13,
                    paddingBottom: 0,
                    textDecoration: 'underline',
                  }}
                >
                  Licenstype
                </Typography>
                <Typography sx={{fontWeight: 400, fontSize: 12}}>
                  Din organisation har en <b>{isEnterprise ? 'Virksomhed' : 'Individuel'}</b>{' '}
                  licens.
                  <br />
                  <br />
                  Læs mere om prismodeller{' '}
                  <Link
                    href="https://www.watsonc.dk/software/desktop/#priser"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    her
                  </Link>
                </Typography>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: 2 / 5,
                  justifyContent: 'center',
                  padding: 1,
                }}
              >
                {isEnterprise ? (
                  <DomainIcon sx={{fontSize: 50, color: 'black'}} />
                ) : (
                  <Diversity3Icon sx={{fontSize: 50, color: 'black'}} />
                )}
                <Typography sx={{fontWeight: 600, fontSize: 16}}>
                  {isEnterprise ? 'Virksomhed' : 'Individuel'}
                </Typography>
              </Box>
            </Box>
          </Paper>
        </SwiperSlide>
        <SwiperSlide style={slideStyle}>
          <Paper
            className="swiper-no-swiping"
            sx={{
              margin: 1,
              display: 'flex',
              padding: 1,
              height: '115px',
              backgroundColor: 'whitesmoke',
            }}
            elevation={3}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                scrollbarWidth: 'thin',
                scrollbarColor: 'rgb(0,120,109, 0.2) transparent',
                '&:hover': {
                  scrollbarColor: 'rgb(0,120,109, 0.5) transparent',
                },
                overflowY: 'scroll',
                minWidth: '105px',
              }}
            >
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: 13,
                  paddingBottom: 0,
                  textDecoration: 'underline',
                }}
              >
                Information
              </Typography>
              <Typography sx={{fontWeight: 400, fontSize: 12}}>
                <b>{org_data?.name}</b>
                <br />
                {org_data?.address}
                <br />
                {org_data?.zip} {org_data?.city} {org_data?.country}
                <br />
                CVR: {org_data?.cvr}
                <br />
                <Link href={`//${org_data?.website}`} target="_blank" rel="noopener noreferrer">
                  {org_data?.website}
                </Link>
                <br />
              </Typography>
            </Box>
            <Box
              sx={{
                width: '100px',
                minWidth: '100px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: 'auto',
                paddingX: 1,
                height: '100%',
                backgroundColor: 'whitesmoke',
              }}
            >
              {editPermissions && (
                <Button
                  bttype="primary"
                  onClick={() => navigate(`/organizations/${viewed_org}/edit`)}
                  startIcon={<Edit />}
                >
                  Rediger
                </Button>
              )}
            </Box>
          </Paper>
        </SwiperSlide>
      </Swiper>
      <KeyboardArrowRightIcon
        className="swiper-button-next"
        style={{
          color: 'green',
          cursor: 'pointer',
          width: '40px',
          position: 'relative',
          marginBottom: 'auto',
          marginLeft: isMobile ? '0px' : '16px',
        }}
      ></KeyboardArrowRightIcon>
    </>
  );
};

export default InformationCarousel;
