import axios from 'axios';

import {apiClient} from './apiClient';
import {authStore} from './state/store';

export type CvrData = {
  name: string;
  address: string;
  city: string;
  zip: string;
  country: string;
};

export type CvrError = {
  error: string;
};

export type LicenseInfoType = {
  available_licenses: number;
  used_licenses: number;
};

let host;
let extEndpoint;
let endpoint;
let userEndpoint;

if (process.env.NODE_ENV === 'developments') {
  // 10.10.158.118
  // 192.168.1.167
  host = 'http://localhost:8080';
  extEndpoint = 'http://localhost:8080/extensions/sensor_app/api';
  // endpoint = `https://watsonc.admin.gc2.io/api/v2/sql/watsonc_clone/?q=`;
  userEndpoint = 'https://backend.calypso.watsonc.dk/rest/';
  endpoint = `https://watsonc.admin.gc2.io/api/v2/sql/watsonc/?q=`;
} else {
  host = 'https://watsonc.admin.gc2.io';
  extEndpoint = 'https://watsonc.admin.gc2.io/extensions/sensor_app/api';
  endpoint = `https://watsonc.admin.gc2.io/api/v2/sql/watsonc/?q=`;
  userEndpoint = 'https://backend.calypso.watsonc.dk/rest/';
}

const getCvr = async (cvr: string) => {
  const response = await apiClient.get<CvrData>(`/admin/get_cvr/${cvr}`);
  return response;
};

const verifyCvr = async (cvr: string) => {
  const response = await apiClient.get<CvrData | CvrError>(`/admin/verify_cvr/${cvr}`);
  return response.data;
};

const getOrgFromCvr = async (cvr: string) => {
  const response = await apiClient.get(`/admin/organization_from_cvr/${cvr}`);
  return response.data;
};

const checkInvoiceDetails = async (orgId: string) => {
  const response = await apiClient.get(`/admin/license/organizations/invoice_details/${orgId}`);
  return response.data;
};

const createUser = (payload: any) => axios.post(`${userEndpoint}calypso/user`, payload);

const resetPassword = async (passReset: {email: string}) =>
  await apiClient.post('/admin/forgot-password', passReset);

const loginUser = (user: string, password: string) => {
  const sessionUrl = `${host}/api/v2/session/start`;
  const loginData = {
    user,
    password,
    schema: null,
  };
  return axios.post(sessionUrl, loginData);
};

const loginAPI = async (username: string, password: string) => {
  const data = new FormData();
  data.append('username', username);
  data.append('password', password);

  return await apiClient.post('/auth/login/secure', data, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
};

const logOut = async () => {
  const response = await apiClient.get('/auth/logout/secure');

  return response;
};

export {
  getCvr,
  verifyCvr,
  createUser,
  loginUser,
  resetPassword,
  loginAPI,
  logOut,
  getOrgFromCvr,
  checkInvoiceDetails,
};
