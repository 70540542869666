import {useMutation, useQuery} from '@tanstack/react-query';
import {toast} from 'react-toastify';
import * as z from 'zod';

import {apiClient} from '~/apiClient';

export type OrgDetails = {
  address: string;
  created_at: string;
  cvr: string;
  id: string | null;
  is_verified: boolean;
  name: string;
  updated_at: string;
  website: string;
  zip: string;
  city: string;
  country: string;
};

export const postOrganization = {
  mutationKey: ['postOrganization'],
  mutationFn: async (organization: OrganizationCreateType) => {
    const {data} = await apiClient.post('/admin/organizations', organization);
    return data;
  },
};

export const putOrganization = {
  mutationKey: ['putOrganization'],
  mutationFn: async (organization: OrganizationType) => {
    const {data} = await apiClient.put(`/admin/organizations`, organization);
    return data;
  },
};

const useMyOrg = () =>
  useQuery({
    queryKey: ['getMyOrganization'],
    queryFn: async () => {
      const {data} = await apiClient.get<OrgDetails>(`/admin/my-organization`);
      return data;
    },
    refetchOnWindowFocus: false,
  });

const useOrgs = () =>
  useQuery({
    queryKey: ['getOrganizations'],
    queryFn: async () => {
      const {data} = await apiClient.get<OrgDetails[]>(`/admin/organizations`);
      return data;
    },
    refetchOnWindowFocus: false,
    placeholderData: [],
  });
const useOrg = (id: string | null) =>
  useQuery({
    queryKey: ['getOrganization', id],
    queryFn: async () => {
      const {data} = await apiClient.get<OrgDetails>(`/admin/organization/${id}`);
      return data;
    },
    enabled: id ? true : false,
    refetchOnWindowFocus: false,
  });

const useEditOrganization = () => {
  const mutation = useMutation({
    ...putOrganization,
    onError: () => {
      toast.error('Der er opstået en fejl');
    },
    onSuccess: () => {
      toast.success('Ændring af organisation er succesfuld!');
    },
  });
  return mutation;
};

const useCreateOrganization = () => {
  const mutation = useMutation({
    ...postOrganization,
    onError: (error: Error) => {
      if ((error as any).response.statusText === 'Not Found')
        toast.error('Virksomheden findes ikke på virk');
      else if ((error as any).response.status === 406)
        toast.error('Organisationen er allerede oprettet i Calypso');
      else if ((error as any).response.status === 400) toast.error('Der er opstået en fejl');
    },
    onSuccess: () => {
      toast.success('Organisationen er oprettet!');
    },
  });
  return mutation;
};

// schema model
const AddOrganizationSchema = z.object({
  name: z
    .string({required_error: 'Navn er påkrævet'})
    .min(2, 'Navn skal være længere end 2 tegn')
    .max(50, 'Navn må ikke være længere end 50 tegn'),
  address: z
    .string({required_error: 'Adresse er påkrævet'})
    .min(2, 'Addresse skal være længere end 2 tegn'),
  zip: z
    .string({required_error: 'Postnummer er påkrævet'})
    .min(2, 'Postnummer skal være længere end 2 tegn'),
  city: z.string({required_error: 'By er påkrævet'}).min(2, 'By skal være længere end 2 tegn'),
  /*  region: z
         .string({ required_error: 'Region er påkrævet' })
         .min(2, 'Fornavn skal være længere end 2 tegn')
         .optional(), */
  country: z
    .string({required_error: 'Land er påkrævet'})
    .min(2, 'Land skal være længere end 2 tegn'),
  website: z
    .string({required_error: 'Hjemmeside er påkrævet'})
    .min(2, 'Hjemmeside skal være længere end 2 tegn')
    .optional()
    .nullish(),
  cvr: z
    .string({required_error: 'CVR er påkrævet'})
    .min(8, 'CVR skal være 8 tegn langt')
    .max(8, 'CVR skal være 8 tegn langt')
    .refine((value) => !isNaN(Number(value)), {message: 'CVR skal være et tal'}),
});

export type OrganizationType = z.infer<typeof AddOrganizationSchema> & {id: string};
export type OrganizationCreateType = z.infer<typeof AddOrganizationSchema>;

export {
  AddOrganizationSchema,
  useMyOrg,
  useOrgs,
  useOrg,
  useCreateOrganization,
  useEditOrganization,
};
