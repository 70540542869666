import {DevTool} from '@hookform/devtools';
import {Visibility, VisibilityOff} from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Fade,
  FormHelperText,
  Typography,
  IconButton,
  InputAdornment,
} from '@mui/material';
import {useState} from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {useLocation, useNavigate} from 'react-router-dom';

import Button from 'src/components/Button';
import FormInput from 'src/components/FormInput';
import LinearLoading from '~/components/LineLoader';

const RegisterForm = ({
  onSubmitHandler,
  isLoading,
}: {
  onSubmitHandler: () => void;
  isLoading: boolean;
}) => {
  const {
    control,
    formState: {errors},
    handleSubmit,
  } = useFormContext();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get('email');

  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Fade in={true}>
      <Box sx={{borderRadius: 4, padding: '48px', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'}}>
        <Typography mb={2} variant="h4">
          Opret bruger
        </Typography>
        <Typography mb={2}>
          Opret din bruger ved at indtaste information nedenunder. Du vil modtage en email med et
          link til at bekræfte din konto.
        </Typography>
        <Box component={'form'} noValidate autoComplete="off">
          <FormInput
            name="firstName"
            label="Fornavn"
            fullWidth
            sx={{
              mb: 2,
            }}
          />
          <FormInput
            name="lastName"
            label="Efternavn"
            fullWidth
            sx={{
              mb: 2,
            }}
          />
          <FormInput
            name="email"
            label="Email"
            disabled={email !== null}
            fullWidth
            sx={{
              mb: 2,
            }}
          />
          <FormInput
            name="password"
            label="Kodeord"
            type={showPassword ? 'text' : 'password'}
            fullWidth
            sx={{
              mb: 2,
            }}
            InputProps={{
              // <-- This is where the toggle button is added.
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleTogglePassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <FormGroup>
            <FormControlLabel
              control={
                <Controller
                  control={control}
                  name="checkedTerms"
                  render={({field: {value, ...field}}) => <Checkbox {...field} checked={!!value} />}
                />
              }
              label={
                <Typography variant="body2">
                  Jeg accepterer{' '}
                  <a href="https://watsonc.dk/privatlivspolitik/" target="_blank" rel="noreferrer">
                    vilkår og betingelser
                  </a>
                </Typography>
              }
            />
            <FormHelperText error={!!errors['checkedTerms']}>
              {errors['checkedTerms'] ? String(errors['checkedTerms'].message) : ''}
            </FormHelperText>
            <FormControlLabel
              control={
                <Controller
                  control={control}
                  name="newsletter"
                  render={({field: {value, ...field}}) => <Checkbox {...field} checked={!!value} />}
                />
              }
              label={
                <Typography variant="body2">
                  Jeg vil gerne modtage mails om nyheder i Calypso
                </Typography>
              }
            />
          </FormGroup>
          <Box sx={{display: 'flex', justifyContent: 'end', gap: 2, alignItems: 'center'}}>
            <Button
              onClick={() => {
                navigate('/');
              }}
              startIcon={<ArrowBackIcon />}
              bttype="tertiary"
              sx={{
                mt: 2,
                mb: 2,
              }}
            >
              Tilbage
            </Button>
            <Box sx={{display: 'flex', width: 85, justifyContent: 'center'}}>
              {!isLoading ? (
                <Button
                  onClick={() => {
                    handleSubmit(onSubmitHandler)();
                  }}
                  bttype="primary"
                >
                  Fortsæt
                </Button>
              ) : (
                <LinearLoading width="medium" height="medium" color="primary" />
              )}
            </Box>
          </Box>
        </Box>
        <DevTool control={control} placement="top-right" />
      </Box>
    </Fade>
  );
};

export default RegisterForm;

/* 
<FormInput
name="cvr"
label="CVR"
disabled={isCvrDisabled}
fullWidth
sx={{
  mb: 2,
}}
/> */
