import {Link} from '@mui/material';
import {useMutation, useQuery} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {z} from 'zod';

import {apiClient} from '~/apiClient';

// import {UserType} from './users';

export enum License {
  GRATIS = 'GRATIS',
  PREMIUM = 'PREMIUM',
}

export type LicenseTableType = {
  type: string;
  user_id?: string | null;
  license_id: string;
  start_date: string;
  renew: boolean;
};

export type InvoiceDetails = {
  address: string;
  att: string;
  city: string;
  country: string;
  created_at: string;
  cvr: string;
  ean: string;
  email: string;
  entity_id: string;
  id: string;
  name: string;
  updated_at: string;
  zip: string;
};

export type LicensingPair = {
  user_id: string | null;
  license_id: string | null;
};

export type SwitchLicensingType = {
  licenseChanges: LicensingPair[];
  org_id: string;
};

export type RenewalInfoType = {
  stop_renewal: string[];
  continue_renewal: string[];
};

export type PostLicensingType = {
  org_id: string;
  count: number;
  user_id?: string | null;
};

export type LicenseInfoType = {
  premium_licences: number;
  paid_for_licences: number;
  available_licenses: number;
  used_licenses: number;
};

const putEditLicensing = {
  mutationKey: ['putEditLicensing'],
  mutationFn: async (licensing: EditLicensingType) => {
    const {data} = await apiClient.put('/admin/license/organizations/invoice_details', licensing);
    return data;
  },
};

const useInvoiceDetails = (orgId: string) =>
  useQuery({
    queryKey: ['getInvoiceDetails', orgId],
    queryFn: async () => {
      const {data} = await apiClient.get<InvoiceDetails>(
        `/admin/license/organizations/invoice_details/${orgId}`
      );
      return data;
    },
    enabled: orgId ? true : false,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60,
  });

const useEditLicensing = () => {
  const mutation = useMutation({
    ...putEditLicensing,
    onError: () => {
      toast.error('Der er opstået en fejl');
    },
    onSuccess: () => {
      toast.success('Ændring af licens er succesfuld!');
    },
  });
  return mutation;
};

const usePostLicensing = () => {
  const mutation = useMutation({
    mutationKey: ['postLicensing'],
    mutationFn: async (licensing: PostLicensingType) => {
      const {data} = await apiClient.post('/admin/license/organizations/licensing', licensing);
      return data;
    },
    onError: () => {
      toast.error('Der er opstået en fejl');
    },
    onSuccess: () => {
      toast.success('Oprettelse af licens er succesfuld!');
    },
  });
  return mutation;
};

const editInvoiceMsg = (editPath: string) => (
  <div>
    Der er ingen faktureringsoplysninger for denne organisation.{' '}
    <Link href={editPath}>Opret faktureringsoplysninger</Link>
  </div>
);
const useSwitchLicensingMutation = () => {
  const params = useParams();
  const org_id = params.orgId;
  const editPath = `${org_id}/edit`;
  return useMutation({
    mutationFn: async (payload: SwitchLicensingType) => {
      const {data} = await apiClient.put(`/admin/license/organizations/licensing/user`, payload);
      return data;
    },
    onSuccess: () => {
      toast.success('Licensændring er succesfuld');
    },
    onError: (e: AxiosError<{detail: string}>) => {
      const msg = e?.response?.data?.detail;
      if (msg === 'Ingen faktureringsoplysninger fundet') {
        toast.error(editInvoiceMsg(editPath));
      } else {
        toast.error(msg);
      }
    },
  });
};

const useLicenseInfo = (org_id: string) =>
  useQuery({
    queryKey: ['getLicenseInfo', org_id],
    queryFn: async () => {
      const {data} = await apiClient.get<LicenseInfoType>(
        `/admin/license/organizations/licensing/${org_id}`
      );
      return data;
    },
    refetchOnWindowFocus: false,
  });

const useLicensingTable = (org_id: string) =>
  useQuery({
    queryKey: ['getLicensingTable', org_id],
    queryFn: async () => {
      const {data} = await apiClient.get<LicenseTableType[]>(
        `/admin/license/organizations/licensing/table/${org_id}`
      );
      return data;
    },
    refetchOnWindowFocus: false,
  });

export const EditLicensingSchema = z.object({
  name: z
    .string()
    .min(2, 'Navn skal være længere end 2 tegn')
    .max(50, 'Navn må ikke være længere end 50 tegn'),
  address: z.string().min(1, 'Adresse er påkrævet'),
  cvr: z
    .string({required_error: 'CVR er påkrævet'})
    .min(8, 'CVR skal være 8 tegn langt')
    .max(8, 'CVR skal være 8 tegn langt')
    .refine((value) => !isNaN(Number(value)), {message: 'CVR skal være et tal'}),
  zip: z.string().min(1, 'Postnummer er påkrævet'),
  city: z.string().min(1, 'By er påkrævet'),
  country: z.string().min(1, 'Land er påkrævet'),
  website: z.string().optional().nullish(),
  email: z.string({required_error: 'E-mail er påkrævet'}).email(),
  att: z.string({required_error: 'Ansvarsperson er påkrævet'}),
  ean: z.string().length(13, 'EAN skal være præcis 13 tegn langt').optional().nullable(),
  entity_id: z.string(),
});

export type EditLicensingType = z.infer<typeof EditLicensingSchema>;

const mapLicenseEnumToString = (license: string | undefined) => {
  switch (license) {
    case License.GRATIS:
      return 'Gratis';
    case License.PREMIUM:
      return 'Premium';
    case undefined:
      return 'Gratis';
  }
};

export {
  useInvoiceDetails,
  useEditLicensing,
  useSwitchLicensingMutation,
  useLicenseInfo,
  useLicensingTable,
  mapLicenseEnumToString,
  usePostLicensing,
};
