import {zodResolver} from '@hookform/resolvers/zod';
import {Save} from '@mui/icons-material';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import {useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useParams} from 'react-router-dom';

import Button from '~/components/Button';
import FormInput from '~/components/FormInput';
import LinearLoading from '~/components/LineLoader';
import {
  EditLicensingSchema,
  EditLicensingType,
  InvoiceDetails,
  useEditLicensing,
} from '~/hooks/queries/licenses';
import {OrgDetails, useOrg} from '~/hooks/queries/organizations';
import {useMe, useOrgUsers} from '~/hooks/queries/users';

type EditLicensingDialogProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  organization: OrgDetails | undefined;
  invoiceDetails: InvoiceDetails | null | undefined;
  setLicenseIntroductionDialog: React.Dispatch<React.SetStateAction<boolean>>;
};

const EditLicensingDialog = ({
  open,
  setOpen,
  invoiceDetails,
  setLicenseIntroductionDialog,
}: EditLicensingDialogProps) => {
  const params = useParams();
  const org_id = params.orgId;
  const {data: orgInfo} = useOrg(org_id ?? '');
  const {data: orgUsers} = useOrgUsers(org_id ?? '');
  const [isEditing, setIsEditing] = useState(false);

  const {data: authed_user} = useMe();
  const formMethods = useForm<EditLicensingType>({
    resolver: zodResolver(EditLicensingSchema),
    defaultValues: {
      ...(invoiceDetails
        ? {...invoiceDetails, entity_id: org_id ?? ''}
        : {
            name: '',
            email: '',
            address: '',
            zip: '',
            city: '',
            country: '',
            att: '',
            cvr: '',
            entity_id: org_id ?? '',
          }),
    },
  });

  const putLicense = useEditLicensing();

  const {handleSubmit, setValue, reset} = formMethods;

  const handleAutofillData = () => {
    if (!orgInfo) {
      return;
    }
    setValue('name', orgInfo.name);
    setValue('address', orgInfo.address);
    setValue('zip', orgInfo.zip);
    setValue('city', orgInfo.city);
    setValue('country', orgInfo.country);
    setValue('cvr', orgInfo.cvr);

    if (authed_user?.org_role != 'SUPERUSER') {
      setValue('att', authed_user?.first_name + ' ' + authed_user?.last_name);
      setValue('email', authed_user?.email ?? '');
    } else if (authed_user?.organization_id !== org_id) {
      const user = orgUsers?.find((user) => user.org_role == 'ADMIN');
      setValue('att', user?.first_name + ' ' + user?.last_name);
      setValue('email', user?.email ?? '');
    } else {
      setValue('att', authed_user?.first_name + ' ' + authed_user?.last_name);
      setValue('email', authed_user?.email ?? '');
    }
  };

  const handleEdit = async (values: EditLicensingType) => {
    setIsEditing(true);
    const licensingData: EditLicensingType = {
      ...values,
      entity_id: org_id ?? '',
      name: values.name,
      email: values.email,
      address: values.address,
      zip: values.zip,
      city: values.city,
      country: values.country,
      att: values.att,
      ean: values.ean,
      cvr: values.cvr,
    };

    putLicense.mutate(licensingData, {
      onSuccess: () => {
        setOpen(false);
        setIsEditing(false);
        if (!invoiceDetails) {
          setLicenseIntroductionDialog(true);
        }
      },
      onError: () => {
        setIsEditing(false);
      },
    });
  };

  const handleClose = () => {
    reset();
    setOpen(false);
  };

  return (
    <FormProvider {...formMethods}>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            borderRadius: 4,
            padding: 1,
            paddingBottom: 0,
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          },
        }}
      >
        <DialogTitle>Rediger faktureringsoplysninger</DialogTitle>
        <DialogContent sx={{paddingBottom: 0}}>
          <DialogContentText mb={2}>
            Rediger din organisations faktureringsoplysninger her. Du kan genbruge
            virksomhedsoplysningerne, hvis informationerne er de samme.
          </DialogContentText>
          <Button sx={{mb: 2}} bttype="primary" onClick={() => handleAutofillData()}>
            Anvend virksomhedsoplysninger
          </Button>
          <FormInput name="name" label="Navn" required fullWidth sx={{mb: 2}} />
          <FormInput name="email" label="Email" required fullWidth sx={{mb: 2}} />
          <FormInput name="address" label="Adresse" required fullWidth sx={{mb: 2}} />
          <FormInput name="zip" label="Postnummer" required fullWidth sx={{mb: 2}} />
          <FormInput name="city" label="By" required fullWidth sx={{mb: 2}} />
          <FormInput name="country" label="Land" required fullWidth sx={{mb: 2}} />
          <FormInput
            name="att"
            label="Ansvarsperson / Kontaktperson"
            required
            fullWidth
            sx={{mb: 2}}
          />
          <FormInput name="ean" label="EAN-nummer" fullWidth sx={{mb: 2}} />
          <FormInput name="cvr" label="CVR" required fullWidth sx={{mb: 2}} />
        </DialogContent>
        <DialogActions sx={{marginRight: 3}}>
          <Button bttype="tertiary" onClick={handleClose}>
            Annuller
          </Button>
          <Box sx={{display: 'flex', width: 91, justifyContent: 'center'}}>
            {!isEditing ? (
              <Button bttype="primary" onClick={handleSubmit(handleEdit)} startIcon={<Save />}>
                Gem
              </Button>
            ) : (
              <LinearLoading width="medium" height="medium" color="primary" />
            )}
          </Box>
        </DialogActions>
      </Dialog>
    </FormProvider>
  );
};

export default EditLicensingDialog;
