import {zodResolver} from '@hookform/resolvers/zod';
import Container from '@mui/material/Container';
import React, {useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useSearchParams} from 'react-router-dom';

import {appBarHeight} from '~/consts';
import {
  RegisterFormValues,
  RegisterSchema,
  useSignup,
  checkEmailExists,
  checkEmailDomain,
} from '~/hooks/queries/useLogin';

import ConfirmDomainDialog from './ConfirmDomainDialog';
import CreateOrganisationDialog from './CreateOrganisationDialog';
import RegisterForm from './signupPages/RegisterForm';

export default function Register() {
  const [domainOrg, setDomainOrg] = useState<string>('');
  const [showForm, setShowForm] = useState<'register' | 'organisation' | 'domain'>('register');
  const [searchParams] = useSearchParams();

  const formMethods = useForm<RegisterFormValues>({
    resolver: zodResolver(RegisterSchema),
    defaultValues: {
      email: searchParams.get('email') || '',
      first_name: '',
      last_name: '',
    },
  });

  const {getValues} = formMethods;
  const signupUser = useSignup();
  const [userCreated, setUserCreated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [mutationError, setMutationError] = useState<string | null>(null);
  const createUser = (values: RegisterFormValues) => {
    const key = encodeURIComponent(searchParams.get('redirect') || '');

    const payload = {
      email: values.email,
      first_name: values.first_name,
      last_name: values.last_name || '',
      join_domain: values.join_domain,
      cvr: values.cvr,
      password: values.password,
      redirectKey: key ? `?redirect=${key}` : '',
    };

    signupUser.mutate(payload, {
      onSuccess: () => {
        setUserCreated(true);
      },
      onError: (error) => {
        const errorMessage = (error as any)?.response?.data?.detail;
        setMutationError(errorMessage);
      },
    });
  };

  const handleCreate = async () => {
    // trigger form validation
    setIsLoading(true);
    const email = getValues().email;
    const emailExists = await checkEmailExists(email);

    if (emailExists) {
      formMethods.setError('email', {
        type: 'manual',
        message: 'Emailen er allerede i brug. Brug en anden email.',
      });
      setIsLoading(false);
      return;
    }

    const org_name = await checkEmailDomain(email);

    if (org_name) {
      setDomainOrg(org_name);
      setShowForm('domain');
    } else {
      setShowForm('organisation');
    }
    setIsLoading(false);
  };

  return (
    <FormProvider {...formMethods}>
      <Container sx={{marginTop: appBarHeight}} maxWidth="sm">
        {showForm == 'register' && (
          <RegisterForm isLoading={isLoading} onSubmitHandler={handleCreate} />
        )}
        {showForm == 'organisation' && (
          <CreateOrganisationDialog
            backToRegister={() => setShowForm('register')}
            createUser={formMethods.handleSubmit(createUser)}
            userCreated={userCreated}
            isMutating={signupUser.isPending}
            mutationError={mutationError}
          />
        )}

        {showForm == 'domain' && (
          <ConfirmDomainDialog
            domainOrg={domainOrg}
            backToRegister={() => setShowForm('register')}
            createUser={formMethods.handleSubmit(createUser)}
            userCreated={userCreated}
            isMutating={signupUser.isPending}
            mutationError={mutationError}
          />
        )}
      </Container>
    </FormProvider>
  );
}
