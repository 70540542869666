import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import Save from '@mui/icons-material/Save';
import {Box, Container} from '@mui/material';
import {
  DropdownOption,
  MRT_ColumnDef,
  MRT_GlobalFilterTextField,
  MRT_ShowHideColumnsButton,
  MRT_TableOptions,
  MRT_ToggleFiltersButton,
  MaterialReactTable,
} from 'material-react-table';
import moment from 'moment';
import 'moment/locale/da';
import {useEffect, useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';

import {checkInvoiceDetails} from '~/api/functions';
import Button from '~/components/Button';
import LinearLoading from '~/components/LineLoader';
import {
  LicenseTableType,
  useLicensingTable,
  SwitchLicensingType,
  useSwitchLicensingMutation,
  LicensingPair,
} from '~/hooks/queries/licenses';
import {useOrgRole} from '~/hooks/queries/permissions';
import {UserType} from '~/hooks/queries/users';
import useBreakpoints from '~/hooks/useBreakpoints';
import {TableTypes, useTable} from '~/hooks/useTable';
import {useStatefullTableAtom} from '~/hooks/useTableState';
import {authStore} from '~/state/store';

import AddLicenseDialog from './AddLicenseDialog';

type LicencesTableProps = {
  orgUsers: UserType[];
  type?: TableTypes;
};

type SelectedUser = Record<string, UserType>;

const getSelectedUsers = (
  orgUsers: UserType[],
  licenseData: LicenseTableType[] | undefined,
  licenseChanges: LicensingPair[]
): SelectedUser[] => {
  if (!licenseData) return [];
  let selectedUsers: SelectedUser[] = [];
  for (const license of licenseData) {
    if (license.user_id) {
      const user = orgUsers.find((user) => user.id === license.user_id) as UserType;
      selectedUsers.push({[license.license_id]: user});
    }
  }

  for (const change of licenseChanges) {
    selectedUsers = selectedUsers.filter(
      (selectedUser) => Object.keys(selectedUser)[0] != change.license_id
    );
    if (change.user_id !== null) {
      const user = orgUsers.find((user) => user.id === change.user_id);
      if (user) {
        selectedUsers.push({[String(change.license_id)]: user});
      }
    }
  }
  return selectedUsers;
};

const LicencesTable = ({orgUsers, type}: LicencesTableProps) => {
  // Utility
  const {superUser} = authStore();
  const {orgId} = useParams();
  const userOrgId = authStore((state) => state.org_id);
  const org_id = orgId || userOrgId || '';
  const role = useOrgRole(org_id ?? '');
  const editPermissions = role === 'ADMIN' || superUser;
  const breakpoints = useBreakpoints();

  const {data: licenseData} = useLicensingTable(org_id); // fetch
  const switchLicensing = useSwitchLicensingMutation(); // mutation

  // modal state
  const [contentTo, setContentTo] = useState<string>('invalidInvoice');
  const [licensingChanges, setLicensingChanges] = useState<LicensingPair[]>([]);
  const [openLicenseDialog, setOpenLicenseDialog] = useState<boolean>(false);

  // table state
  const [hasEditChanges, setHasEditChanges] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const selectedUsers = getSelectedUsers(orgUsers, licenseData, licensingChanges);
  const notSelectedUsers = orgUsers?.filter((user) => {
    return !selectedUsers?.some((selectedUser) => Object.values(selectedUser)[0]?.id === user?.id);
  });
  const notSelectedUsersDropdownOptions: DropdownOption[] = (notSelectedUsers || []).map(
    (user) => ({
      value: user.id,
      label: `${user.first_name} ${user.last_name}`,
    })
  );

  useEffect(() => {
    checkInvoiceDetails(org_id).then((response) => {
      if (response != false) {
        setContentTo('validInvoice');
      } else {
        setContentTo('invalidInvoice');
      }
    });
  }, [org_id]);

  useEffect(() => {
    if (licensingChanges.length === 0) {
      setHasEditChanges(false);
      return;
    }

    let hasChanges = false;
    for (const change of licensingChanges) {
      const originalLicense = licenseData?.find(
        (license) => license.license_id === change.license_id
      );
      if (originalLicense && originalLicense.user_id !== change.user_id) {
        hasChanges = true;
        break;
      }
    }

    setHasEditChanges(hasChanges);
  }, [licenseData, licensingChanges]);

  const columns = useMemo<MRT_ColumnDef<LicenseTableType>[]>(
    () => [
      {
        accessorKey: 'type',
        header: 'Type',
        enableEditing: false,
        editVariant: 'text',
        size: 40,
        Cell: ({row}) => {
          if (row.original.type === 'INDIVIDUAL') {
            return (
              <Box sx={{display: 'flex', fontSize: '0.875rem', alignItems: 'center'}}>Premium</Box>
            );
          }
          return null; // Add this line to handle other cases
        },
      },
      {
        accessorKey: 'user_id',
        header: 'Tildelt',
        editVariant: 'select',
        editSelectOptions: ({row}) => {
          const selectedUser = selectedUsers.find((selectedUser) => {
            if (Object.keys(selectedUser)[0] == row.original.license_id) {
              return true;
            }
          })?.[row.original.license_id];

          let list = [];
          if (selectedUser != undefined) {
            list = [
              ...notSelectedUsersDropdownOptions,
              {
                value: selectedUser?.id,
                label: `${selectedUser?.first_name} ${selectedUser?.last_name}`,
              },
              {
                value: 'tom',
                label: 'Ikke Tildelt',
              },
            ];
          } else {
            list = [
              ...notSelectedUsersDropdownOptions,
              {
                value: 'tom',
                label: 'Ikke Tildelt',
              },
            ];
          }

          list = list.filter((option) => option !== undefined && option !== null);

          return list;
        },
        muiTableBodyCellProps: () => ({
          sx: {
            padding: '0em',
          },
        }),

        muiEditTextFieldProps: ({row}) => ({
          value:
            selectedUsers.find((selectedUser) => {
              if (Object.keys(selectedUser)[0] == row.original.license_id) {
                return true;
              }
            })?.[row.original.license_id]?.id || 'tom',
          select: true,
          variant: 'standard',
          color: 'primary',
          sx: {
            padding: '0.0em',
            '& .MuiSelect-select.MuiInputBase-input.MuiInput-input': {
              padding: 1,
              paddingRight: 2,
              fontSize: '0.875rem', // Modify the font size here
            },
            textOverflow: 'ellipsis',
            maxWidth: '175px',
          },
          defaultValue: 'tom',
          onChange: (e) => {
            const newUserId = e.target.value;

            setLicensingChanges((prevLicensingChanges) => {
              const updatedChanges = prevLicensingChanges.filter(
                (prevChange) => prevChange.license_id !== row.original.license_id
              );

              return [
                ...updatedChanges,
                {
                  license_id: row.original.license_id,
                  user_id: newUserId === 'tom' ? null : newUserId,
                },
              ];
            });
          },
        }),
      },
      /* Edit: ({cell, row}) => {
          const index: number = row?.index;
          const selectedUser = selectedUsers[index];
          return (
            <UserLicenseDropdownSelect
              selectedUsers={selectedUsers}
              dropdownOptions={notSelectedUsers}
            />
          );
        }, */

      {
        accessorKey: 'start_date',
        header: 'Start dato',
        size: 110,
        enableEditing: false,
        editVariant: 'text',
        Cell: ({row}) => {
          return (
            <Box sx={{display: 'flex', fontSize: '0.875rem', alignItems: 'center'}}>
              {moment(row.original.start_date).format('DD-MM-YYYY')}
            </Box>
          );
        },
      },
      {
        accessorKey: 'renew',
        header: 'Fornyes',
        enableEditing: false,
        size: 30,
        Cell: ({row}) => {
          return (
            <Box sx={{display: 'flex', fontSize: '0.875rem', alignItems: 'center'}}>
              {row.original.user_id ? 'Ja' : 'Nej'}
            </Box>
          );
        },
      },
      /*       {
        accessorKey: 'license_id',
        header: 'Licens ID',
        enableEditing: false,
      }, */
    ],
    [selectedUsers, notSelectedUsersDropdownOptions]
  );

  const [tableState] = useStatefullTableAtom<LicenseTableType>('org_license_table', {
    columnVisibility: {
      mobile: false,
      is_verified: false,
      license_id: false,
      newsletter: false,
    },
  });
  const handleSaveEditedUsers = () => {
    if (!licenseData) return;
    setIsEditing(true);

    const listOfChanges = licensingChanges;
    const actualListOfChanges = listOfChanges.filter((change) => {
      if (
        licenseData.some(
          (license) =>
            license.license_id === change.license_id && license.user_id === change.user_id
        )
      ) {
        return false;
      } else {
        return true;
      }
    });

    const changeLicensingStructure: SwitchLicensingType = {
      licenseChanges: actualListOfChanges,
      org_id: org_id,
    };
    switchLicensing.mutate(changeLicensingStructure);
    setLicensingChanges([]);
    setIsEditing(false);
  };

  const options: Partial<MRT_TableOptions<LicenseTableType>> = {
    editDisplayMode: 'table', // ('modal', 'row', 'cell', and 'custom' are also
    enableEditing: true,
    getRowId: (row) => row.user_id as string,
    renderTopToolbar: () => {
      if (!editPermissions) return null;

      if (breakpoints.isMobile)
        return (
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              minHeight: '100px',
              mx: 0.5,
            }}
          >
            <Box sx={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
              {(role === 'ADMIN' || superUser) && (
                <Button
                  bttype="primary"
                  sx={{height: '40px', mr: 0.5}}
                  startIcon={<AdminPanelSettingsIcon />}
                  onClick={() => {
                    setOpenLicenseDialog(true);
                  }}
                >
                  Tilføj ny licens
                </Button>
              )}

              <Button
                sx={{height: '40px'}}
                color="success"
                bttype="primary"
                disabled={!hasEditChanges && !isEditing}
                startIcon={<Save />}
                onClick={() => {
                  handleSaveEditedUsers();
                }}
              >
                {isEditing ? (
                  <LinearLoading width="x-small" height="small" color="primary" />
                ) : (
                  'Gem'
                )}
              </Button>
            </Box>
            <Box sx={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
              <MRT_GlobalFilterTextField table={table} sx={{width: '200px'}} />
              <Box
                sx={{
                  width: '90px',
                  display: 'flex',
                  alignItems: 'start',
                  justifyContent: 'space-evenly',
                }}
              >
                <MRT_ToggleFiltersButton table={table} />
                <MRT_ShowHideColumnsButton table={table} />
              </Box>
            </Box>
          </Box>
        );

      return (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 1,
            margin: 1,
          }}
        >
          <Box sx={{display: 'flex'}}>
            {(role === 'ADMIN' || superUser) && (
              <>
                <Button
                  bttype="primary"
                  sx={{height: '40px', mr: 1, minWidth: '150px'}}
                  startIcon={<AdminPanelSettingsIcon />}
                  onClick={() => {
                    setOpenLicenseDialog(true);
                  }}
                >
                  Tilføj ny licens
                </Button>
              </>
            )}

            <Button
              color="success"
              bttype="primary"
              disabled={!hasEditChanges}
              startIcon={<Save />}
              onClick={() => {
                handleSaveEditedUsers();
              }}
            >
              Gem
            </Button>
          </Box>

          <Box sx={{display: 'flex', gap: 0.5}}>
            <MRT_GlobalFilterTextField table={table} />
            <MRT_ToggleFiltersButton table={table} />
            <MRT_ShowHideColumnsButton table={table} />
          </Box>
        </Box>
      );
    },
    muiTableBodyRowProps: () => ({
      sx: {
        cursor: 'pointer', //you might want to change the cursor too when adding an onClick
      },
    }),
    enableBottomToolbar: false,
  };

  const table = useTable(columns, licenseData, options, tableState, type);

  if (!editPermissions) {
    return null;
  }
  return (
    <>
      <Container
        sx={{
          my: 0,
          mx: 0,
          paddingRight: '0em !important',
          paddingLeft: '0em !important',
          width: '100%',
          maxWidth: '100% !important',
          height: '100%',
        }}
      >
        <AddLicenseDialog
          open={openLicenseDialog}
          setOpen={setOpenLicenseDialog}
          userOptions={notSelectedUsers}
          showPageString={contentTo}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: breakpoints.isMobile
              ? type === TableTypes.TABLE
                ? 'calc(100vh - 210px)'
                : 'calc(100vh - 195px)'
              : 'calc(100vh - 350px)',
            minHeight: '420px',
          }}
        >
          <MaterialReactTable table={table} />
        </Box>
      </Container>
    </>
  );
};

export default LicencesTable;
