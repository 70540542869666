import {Fade, Box, Typography} from '@mui/material';
import React from 'react';
import {useFormContext} from 'react-hook-form';

import Button from 'src/components/Button';
import type {RegisterFormValues} from '~/pages/login/Register';

interface DecideOrgNoOrgProps {
  page: string;
  setPage: React.Dispatch<
    React.SetStateAction<'decideOrgOrNoOrg' | 'enterCVR' | 'confirmOrg' | 'creationConfirmed'>
  >;
  backToRegister: () => void;
  finishCreation: () => void;
}

const DecideOrgNoOrg = ({page, setPage, backToRegister, finishCreation}: DecideOrgNoOrgProps) => {
  const {getValues} = useFormContext<RegisterFormValues>();

  return (
    <Fade in={page === 'decideOrgOrNoOrg'}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
        }}
      >
        <Box>
          <Typography mb={4} variant="h5">
            Hej {getValues('firstName')}!
          </Typography>
        </Box>

        <Box>
          <div>
            <Typography mb={2}>Ønsker du at oprette en organisation? </Typography>
            <Typography>Du skal bruge CVR nummeret for den virksomhed, du vil oprette. </Typography>
            <Typography mb={2}>
              Hvis virksomheden ikke findes på Virk, kan den ikke oprettes i Calypso.{' '}
            </Typography>
            <Typography>Ønsker du at fortsætte?</Typography>
          </div>
        </Box>
        <Box sx={{display: 'flex', justifyContent: 'end', gap: 2, mt: 4}}>
          <Button onClick={backToRegister} bttype="tertiary">
            Gå tilbage
          </Button>
          <Box sx={{display: 'flex', gap: 2}}>
            <Button onClick={() => finishCreation()} bttype="primary">
              Opret uden
            </Button>
            <Button onClick={() => setPage('enterCVR')} bttype="primary">
              Opret Organisation
            </Button>
          </Box>
        </Box>
      </Box>
    </Fade>
  );
};

export default DecideOrgNoOrg;
