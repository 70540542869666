import {useMutation, useQuery} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {toast} from 'react-toastify';
import * as z from 'zod';

import {apiClient} from '~/api/apiClient';
import {email, first_name, last_name, mobile, newsletter, organization_id, role} from '~/consts';
import {Role} from '~/state/store';

export type UserType = {
  first_name: string;
  last_name: string;
  email: string;
  org_role: Role;
  organization_id: string;
  organization_name: string;
  created_at: string;
  updated_at: string;
  id: string;
  password?: string;
  license: string | undefined;
  mobile?: string | undefined;
  is_verified?: boolean;
  newsletter?: boolean;
};

export type UserPost = {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  org_role: Role;
  organization_id: string;
};

export const postUserMutation = {
  mutationKey: ['postUser'],
  mutationFn: async (user: UserCreateType) => {
    const {data} = await apiClient.post('/admin/user', user);
    return data;
  },
};

export const createInviteMutation = {
  mutationKey: ['createInvite'],
  mutationFn: async (user: InviteUserType) => {
    const {data} = await apiClient.post('/admin/create_invite', user);
    return data;
  },
};

export const putUserMutation = {
  mutationKey: ['putUser'],
  mutationFn: async (user: UserEditType & {user_id: string}) => {
    const {data} = await apiClient.put(`/admin/user/${user.user_id}`, user);
    return data;
  },
};

export const delUserMutation = {
  mutationKey: ['deleteUser'],
  /*     mutationFn: async (user: UserType) => {
            const { data } = await apiClient.delete(`/admin/user/${user.id}`);
            return data;
        }, */
};

// get users from specific organizatio

export const checkExistingKlaviyoOrSystemUser = async (email: string) => {
  const response = await apiClient.get(
    `/admin/klaviyo/check_existing_klaviyo_or_system_user/${email}`
  );

  return !response.data;
};

const useUsers = () =>
  useQuery({
    queryKey: ['getUsers'],
    queryFn: async () => {
      const {data} = await apiClient.get<UserType[]>(`/admin/users`);
      return data;
    },
    refetchOnWindowFocus: false,
    placeholderData: [],
  });

const useUser = (user_id: string) =>
  useQuery({
    queryKey: ['getUser', user_id],
    queryFn: async () => {
      const {data} = await apiClient.get<UserType>(`/admin/user/${user_id}`);
      return data;
    },
    refetchOnWindowFocus: false,
  });

const useMe = () =>
  useQuery({
    queryKey: ['getMe'],
    queryFn: async () => {
      const {data} = await apiClient.get<UserType>(`/admin/my-user`);
      return data;
    },
    refetchOnWindowFocus: false,
  });
const useOrgUsers = (org_id: string | null) =>
  useQuery({
    queryKey: ['getOrgUsers', org_id],
    queryFn: async () => {
      const {data} = await apiClient.get<UserType[]>(`/admin/users/${org_id}`);
      return data;
    },
    refetchOnWindowFocus: false,
  });

const useCheckExistingKlaviyoOrSystemUser = (email: string) =>
  useQuery({
    queryKey: ['checkExistingKlaviyoOrSystemUser', email],
    queryFn: () => checkExistingKlaviyoOrSystemUser(email),
    refetchOnWindowFocus: false,
    enabled: email !== '',
  });

const updateKlaviyoPreferencesMutation = {
  mutationKey: ['putUser'],
  mutationFn: async (email_preferences: EmailPreferencesType) => {
    const {data} = await apiClient.patch(
      `/admin/klaviyo/email_preferences/${email_preferences.email}`,
      email_preferences
    );
    return data;
  },
};

const useCreateUser = () => {
  return useMutation({
    ...postUserMutation,
    onError: (e: AxiosError<{detail: string}>) => {
      toast.error(e?.response?.data?.detail);
    },
    onSuccess: () => {
      toast.success('Bruger oprettet');
    },
  });
};

const useCreateInvite = () => {
  return useMutation({
    ...createInviteMutation,
    onError: (e: AxiosError<{detail: string}>) => {
      toast.error(e?.response?.data?.detail);
    },
    onSuccess: () => {
      toast.success('Bruger inviteret');
    },
  });
};

const useEditUser = () => {
  return useMutation({
    ...putUserMutation,
    onError: () => {
      toast.error('Der opstod en fejl.');
    },
    onSuccess: () => {
      toast.success('Brugeren er ændret');
    },
  });
};

const useDeleteUser = () => {
  return useMutation({
    ...delUserMutation,
    onError: () => {
      toast.error('Something went wrong');
    },
    onSuccess: () => {
      toast.success('Brugeren er slettet');
    },
  });
};

const useUpdateEmailPreferences = () => {
  return useMutation({
    ...updateKlaviyoPreferencesMutation,
    onError: () => {
      toast.error('Der opstod en fejl.');
    },
    onSuccess: () => {
      toast.success('Dine præferencer er gemt');
    },
  });
};

const AddUserSchema = z.object({
  first_name,
  last_name,
  email,
  org_role: role,
  organization_id,
});

const EditUserSchema = z.object({
  first_name,
  last_name,
  mobile,
  newsletter,
  // email: z.string({ required_error: 'Email er på krævet' }).email('Ugyldig email'),
  organization_id,
});

const InviteUserSchema = z.object({
  email,
  role,
  org_id: organization_id,
});

const EmailPreferencesSchema = z.object({
  email: email,
  software: z.boolean(),
  vandloeb_overfladevand: z.boolean(),
  grundvand: z.boolean(),
  natur: z.boolean(),
  produkter_vandloeb: z.boolean(),
  produkter_grundvand: z.boolean(),
  dambrug: z.boolean(),
});

export type UserCreateType = z.infer<typeof AddUserSchema>;
export type UserEditType = z.infer<typeof EditUserSchema>;
export type InviteUserType = z.infer<typeof InviteUserSchema>;
export type EmailPreferencesType = z.infer<typeof EmailPreferencesSchema>;

// const handleNullValues = (user: UserType) => {
//   if (!user.first_name) {
//     user.first_name = 'Ikke angivet';
//   }
//   if (!user.last_name) {
//     user.last_name = 'Ikke angivet';
//   }
//   if (!user.email) {
//     user.email = 'Ikke angivet';
//   }
//   if (!user.org_role) {
//     user.org_role = Role.USER;
//   }
//   if (!user.mobile) {
//     user.mobile = 'Ikke angivet';
//   }
//   if (!user.is_verified) {
//     user.is_verified = false;
//   }
//   if (!user.license) {
//     user.license = License.GRATIS;
//   }
//   if (!user.newsletter) {
//     user.newsletter = false;
//   }
//   if (!user.organization_id) {
//     user.organization_id = '';
//   }
//   return user;
// };

const mapRoleEnumToString = (role: Role): string => {
  switch (role) {
    case Role.ADMIN:
      return 'Administrator';
    case Role.USER:
      return 'Bruger';
    case Role.PENDING:
      return 'Afventer';
    case Role.SUPERUSER:
      return 'Superbruger';
    default:
      return 'Bruger';
  }
};

const mapRoleStringToEnum = (role: string): Role => {
  switch (role) {
    case 'Administrator':
      return Role.ADMIN;
    case 'Bruger':
      return Role.USER;
    case 'Afventer':
      return Role.PENDING;
    case 'Superbruger':
      return Role.SUPERUSER;
    default:
      throw new Error(`Unknown role: ${role}`);
  }
};

export {
  useMe,
  useUsers,
  useCreateUser,
  useCreateInvite,
  useEditUser,
  useDeleteUser,
  useUser,
  useCheckExistingKlaviyoOrSystemUser,
  useOrgUsers,
  useUpdateEmailPreferences,
  AddUserSchema,
  EditUserSchema,
  InviteUserSchema,
  EmailPreferencesSchema,
  mapRoleEnumToString,
  mapRoleStringToEnum,
};
