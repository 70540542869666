import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import {
  AppBar,
  Box,
  Button,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@mui/material';
import {useQueryClient} from '@tanstack/react-query';
import {MouseEventHandler, ReactNode, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import LogoSvg from '~/calypso.svg?react';
import {appBarHeight} from '~/consts';
import {authStore} from '~/state/store';

import {logOut} from './fieldAPI';
import {useMe} from './hooks/queries/users';
import useBreakpoints from './hooks/useBreakpoints';

export const AppBarLayout = ({children}: {children?: ReactNode}) => {
  return (
    <AppBar
      position="fixed"
      enableColorOnDark
      sx={{height: appBarHeight, zIndex: (theme) => theme.zIndex.drawer + 1}}
    >
      <Toolbar
        sx={{
          height: appBarHeight,
          flexDirection: 'row',
          justifyContent: 'space-between',
          boxShadow: '0px 0px 0px 0px rgba(0,0,0,0.2)',
        }}
      >
        {children}
      </Toolbar>
    </AppBar>
  );
};

const LogOut = ({children}: {children?: ReactNode}) => {
  const [resetState] = authStore((state) => [state.resetState]);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const handleLogout = () => {
    resetState();
    logOut();
    navigate('/');
    queryClient.clear();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        paddingX: 2,
        paddingY: 1,
        width: '100%',
        height: '100%',
      }}
      onClick={handleLogout}
    >
      {children}
    </Box>
  );
};

export const NavBarMenu = ({
  highligtFirst,
  items,
}: {
  highligtFirst?: boolean;
  items?: {
    title: string;
    icon: ReactNode;
    onClick: () => void;
  }[];
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    if (event.currentTarget instanceof HTMLElement) setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {highligtFirst && items != undefined && items.length > 0 && (
        <Button variant="contained" onClick={items?.[0].onClick} startIcon={items?.[0].icon}>
          {items?.[0].title}
        </Button>
      )}
      <IconButton
        size="large"
        color="inherit"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        sx={{
          paddingY: 0,
          '& .MuiMenu-list': {
            paddingY: 0,
          },
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {items
          ?.filter((item, index) => (highligtFirst ? !(index == 0) : true))
          ?.map((item) => (
            <MenuItem
              sx={{
                display: 'flex',
                alignItems: 'center',
                paddingX: 2,
                paddingY: 1,
                width: '100%',
                height: '100%',
              }}
              key={item.title}
              onClick={() => {
                handleClose();
                item.onClick();
              }}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              {item.title}
            </MenuItem>
          ))}

        <MenuItem sx={{padding: 0}} onClick={handleClose}>
          <LogOut>
            <ListItemIcon>
              <LogoutIcon fontSize="medium" />
            </ListItemIcon>
            Log ud
          </LogOut>
        </MenuItem>
      </Menu>
    </>
  );
};

const Logo = () => {
  return <LogoSvg />;
};
const NavBar = ({
  setOpenSideDrawer,
}: {
  setOpenSideDrawer?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const {isDesktop} = useBreakpoints();
  const [authenticated] = authStore((state) => [state.authenticated]);

  const {data: user} = useMe();
  const navigate = useNavigate();

  const menuItems = [];

  if (!(user?.organization_id == undefined || user?.organization_id == '')) {
    menuItems.push({
      title: 'Overblik',
      icon: <CorporateFareIcon />,
      onClick: () => navigate(`/`),
    });
  }

  menuItems.push({
    title: 'Profile',
    icon: <PersonOutlineIcon />,
    onClick: () => navigate(`/profile`),
  });

  return (
    <AppBarLayout>
      {setOpenSideDrawer !== undefined && !isDesktop && user?.org_role == 'SUPERUSER' && (
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={() => setOpenSideDrawer((open) => !open)}
          edge="start"
        >
          <MenuIcon />
        </IconButton>
      )}
      <Logo />
      {isDesktop && user && (
        <Typography variant="h4">{user?.first_name + ' ' + user?.last_name}</Typography>
      )}
      {authenticated ? <NavBarMenu items={menuItems} /> : <Box />}
    </AppBarLayout>
  );
};

export default NavBar;
