import React from 'react';

interface FirstIndividualLicenseProps {
  setPage: React.Dispatch<
    React.SetStateAction<
      'licenseManagement' | 'firstIndividualLicense' | 'secondIndividualLicense' | 'companyLicense'
    >
  >;
}

const FirstIndividualLicense = ({setPage}: FirstIndividualLicenseProps) => {
  return (
    <div>
      <h1>First Individual License</h1>
      <p>Welcome to the first individual license page.</p>
    </div>
  );
};
export default FirstIndividualLicense;
